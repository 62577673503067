import api from 'configs/api'
import { useDoctor } from 'context/doctors/doctors.context'
import { SpecialtyProps } from 'context/doctors/doctors.types'
import { setStorage } from 'utils/storage'

export default () => {
  const { setSpecialties, specialties } = useDoctor()

  const getSpecialties = async () => {
    try {
      const response = await api.get(
        '/doctors/v1/specialties?page=1&limit=100',
        {
          headers: {
            'Accept-Language': 'pt-BR',
          },
        }
      )

      const datas = response?.data?.specialties?.map((data: any) => ({
        id: data.id,
        name: data.name,
        status: data.status,
        attendanceTime: data.attendance_time,
        createdAt: data.created_at,
        activeB2c: data.active_b2c,
      }))

      setStorage('@ManagerStarbem:speciatiesData', datas)

      setSpecialties(datas)
      return {
        status: true,
        datas,
      }
    } catch (err: any) {
      return {
        status: false,
        msg: err?.response?.data?.message,
      }
    }
  }
  const createSpecialty = async ({
    name,
    status,
    attendanceTime,
  }: SpecialtyProps) => {
    try {
      const response = await api.post('manager/specialty', {
        name,
        status,
        attendance_time: Number(attendanceTime),
      })

      const { item } = response.data

      const data = {
        id: item.id,
        uuid: item.uuid,
        name: item.name,
        status: item.status,
        statusDescription: item.status_description,
        attendanceTime: item.attendance_time,
        createdAt: item.created_at,
      }
      setSpecialties([...specialties, data])
      return {
        status: true,
        item,
      }
    } catch (err: any) {
      return {
        status: false,
        msg: err?.response?.data?.message,
      }
    }
  }
  const updateSpecialty = async ({
    uuid,
    name,
    status,
    attendanceTime,
  }: SpecialtyProps) => {
    try {
      await api.put(`manager/specialty/${uuid}`, {
        name,
        status,
        attendance_time: Number(attendanceTime),
      })
      specialties.forEach((specialty: any) => {
        if (specialty.id === uuid) {
          specialty.name = name
          specialty.status = status
          specialty.attendanceTime = attendanceTime
          specialty.statusDescription =
            status === 'active' ? 'Ativo' : 'Inativo'
        }
      })

      return {
        status: true,
      }
    } catch (err: any) {
      return {
        status: false,
        msg: err?.response?.data?.message,
      }
    }
  }
  const toglleStatusSpecialtyB2c = async (uuid: string) => {
    try {
      await api.post(`manager/specialty/b2c/${uuid}`)
      specialties.forEach((specialty: any) => {
        if (specialty.id === uuid) {
          specialty.activeB2c = !specialty.activeB2c
        }
      })

      return {
        status: true,
      }
    } catch (err: any) {
      return {
        status: false,
        msg: err?.response?.data?.message,
      }
    }
  }

  const filterSpecialtiesActive = () =>
    specialties.filter((specialty: any) => specialty.status === 'active')

  return {
    specialties,
    setSpecialties,
    getSpecialties,
    createSpecialty,
    updateSpecialty,
    filterSpecialtiesActive,
    toglleStatusSpecialtyB2c,
  }
}
