import React, { useState } from 'react'
import classnames from 'classnames'

import {
  Card,
  Nav,
  NavItem,
  Container,
  NavLink,
  CardBody,
  TabContent,
  TabPane,
} from 'reactstrap'

import HeaderNeutro from 'components/Headers/HeaderNeutro'

import { TabSettings, TabSchedules } from 'components/Pages/Specialties'

const Specialties = () => {
  const [tabs, setTabs] = useState(1)

  return (
    <div>
      <HeaderNeutro />
      <Container fluid>
        <div className="nav-wrapper">
          <Nav
            className="flex-column flex-md-row"
            id="tabs-icons-text"
            pills
            role="tablist"
          >
            <NavItem>
              <NavLink
                aria-selected={tabs === 1}
                className={classnames('mb-sm-3 mb-md-0', {
                  active: tabs === 1,
                })}
                onClick={() => setTabs(1)}
                href="#Configurações"
                role="tab"
              >
                <i className="ni ni-building mr-2" />
                Especialidades Cadastradas
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                aria-selected={tabs === 2}
                className={classnames('mb-sm-3 mb-md-0', {
                  active: tabs === 2,
                })}
                onClick={() => setTabs(2)}
                href="#agenda"
                role="tab"
              >
                <i className="fas fa-users mr-2" />
                Agenda
              </NavLink>
            </NavItem>
          </Nav>
        </div>
        <Card className="shadow">
          <CardBody className="p-0">
            <TabContent activeTab={`tabs${tabs}`}>
              <TabPane tabId="tabs1">
                <TabSettings />
              </TabPane>
              <TabPane tabId="tabs2">
                <TabSchedules />
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}
export default Specialties
