import React, { useMemo, useEffect } from 'react'
import * as yup from 'yup'
import { useFormik, FormikProvider } from 'formik'
import api from 'configs/api'
import { alertSuccess, alertWarning } from 'utils/toast'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Label,
  Form,
  FormGroup,
  Row,
  Col,
} from 'reactstrap'

const validationSchema = yup.object().shape({
  question: yup.string().required('Informe a pergunta!'),
  answer: yup.string().required('Informe a resposta!'),
  type: yup.string(),
})

const ModalFaq = ({ modal, setModal, action, faq }: any) => {
  const initialValues = useMemo(
    () => ({
      question: '',
      answer: '',
      type: 'app',
      lang: 'pt-BR',
    }),
    []
  )

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      if (faq?.id) {
        try {
          await api.put(`manager/faq/${faq?.id}`, {
            ...values,
            status: 'active',
          })
          action()
          formik.resetForm()
          alertSuccess('FAQ alterada com sucesso.')
        } catch (err: any) {
          alertWarning(err?.response?.data?.message || 'ERRO ao editar FAQ')
        }
      } else {
        try {
          await api.post(`manager/faq`, values)
          action()
          formik.resetForm()
          alertSuccess('FAQ cadastrada com sucesso.')
        } catch (err: any) {
          alertWarning(err?.response?.data?.message || 'ERRO ao cadastrar FAQ')
        }
      }
    },
  })

  const toggle = () => {
    formik.resetForm()
    setModal(!modal)
  }
  useEffect(() => {
    if (faq?.id) {
      formik.setValues({
        question: faq.question,
        answer: faq.answer,
        type: faq.type,
        lang: faq.lang,
      })
    }
  }, [faq])

  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        <h3>{faq?.id ? 'Editar FAQ' : 'Cadastrar nova FAQ'}</h3>
      </ModalHeader>
      <ModalBody>
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit} autoComplete="off">
            <Row>
              <Col sm="12">
                <FormGroup>
                  <Label for="question">Pergunta</Label>
                  <Input
                    type="textarea"
                    name="question"
                    id="question"
                    autoComplete="off"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.question}
                    error={formik.errors.question}
                  />
                  {formik.errors.question && formik.touched.question && (
                    <div className="input-feedback">
                      {formik.errors.question}
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col sm="12">
                <FormGroup>
                  <Label for="answer">Resposta</Label>
                  <Input
                    type="textarea"
                    name="answer"
                    id="answer"
                    autoComplete="off"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.answer}
                    error={formik.errors.answer}
                  />
                  {formik.errors.answer && formik.touched.answer && (
                    <div className="input-feedback">{formik.errors.answer}</div>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm="6">
                <FormGroup>
                  <Label for="type">Tipo</Label>
                  <Input
                    type="select"
                    name="type"
                    id="type"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.type}
                  >
                    <option value="app">Aplicativo</option>
                    <option value="app-internacional">App Global</option>
                    <option value="site">Web Site</option>
                    <option value="site-internacional">
                      Web Site Internacional
                    </option>
                    {/* <option value="doctor">Area Médica</option>
                    <option value="company">Portal Empresa</option> */}
                  </Input>
                </FormGroup>
              </Col>
              <Col sm="6">
                <FormGroup>
                  <Label for="lang">Linguagem</Label>
                  <Input
                    type="select"
                    name="lang"
                    id="lang"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.lang}
                  >
                    <option value="pt-BR">Português</option>
                    <option value="en">Inglês</option>
                    <option value="es">Espanhol</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row className="d-flex justify-content-end">
              <Col sm="6">
                <Button outline color="secondary" onClick={toggle}>
                  Cancelar
                </Button>
              </Col>
              <Col sm="6">
                <Button type="submit" color="secondary">
                  {faq?.id ? 'Alterar' : 'Cadastrar'}
                </Button>
              </Col>
            </Row>
          </Form>
        </FormikProvider>
      </ModalBody>
    </Modal>
  )
}

export default ModalFaq
