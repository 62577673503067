import React, { useEffect, useState } from 'react'
import * as yup from 'yup'
import { FormikProvider, useFormik } from 'formik'
// Components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Table,
  Modal,
  ModalHeader,
  ModalFooter,
} from 'reactstrap'
import HeaderNeutro from 'components/Headers/HeaderNeutro'
import api from 'configs/api'
import { AdminProps } from 'context/authentication/authentication.types'
import { alertSuccess, alertWarning } from 'utils/toast'
import Loading from 'components/Loading'
import InputPassword from 'components/InputPassword'
import PaginationComponent from 'components/PaginationComponent'

const regexPassword = /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])/

interface userProps extends AdminProps {
  password?: string
  passwordConfirmation?: string
}

const defaultUserProps: userProps = {
  id: '',
  name: '',
  email: '',
  password: '',
  passwordConfirmation: '',
  roles: '',
  status: 'active',
}

const schemaUser = yup.object().shape({
  name: yup.string().required('Informe o nome!'),
  email: yup
    .string()
    .required('Informe o email!')
    .email('Informe um email valido!'),
  roles: yup.string().required('Selecione um tipo de usuário!'),
  password: yup
    .string()
    .required('Informe uma senha!')
    .min(8, 'A senha deve conter no minimo 8 caracteres!')
    .test(
      'test',
      'A senha deve conter letras maiusculas e minusculas, numeros e caracteres especiais!',
      (value) => {
        if (!value) return false
        return regexPassword.test(value)
      }
    ),
})

const schemaUserChange = yup.object().shape({
  name: yup.string().required('Informe o nome!'),
  email: yup
    .string()
    .required('Informe o email!')
    .email('Informe um email valido!'),
  roles: yup.string().required('Selecione um tipo de usuário!'),
})

const Administer = () => {
  const [paginationOn, setPaginationOn] = useState(true)
  const [numberOfPages, setNumberOfPages] = useState(0)
  const [page, setPage] = useState(1)
  const limit = 10
  const [name, setName] = useState('')

  const [loading, setLoading] = useState<boolean>(false)
  const [messageLoading, setMessageLoading] = useState('')

  const [usersData, setUsersData] = useState<AdminProps[]>()
  const [typeUser, setTypeUser] = useState(false)
  const [userUuid, setUserUuid] = useState('')
  // const [alterUser, setAlterUser] = useState<userProps | null>()
  const [modal, setModal] = useState(false)

  const [isAlteration, setIsAlteration] = useState(false)

  const toggle = () => setModal(!modal)

  function handleClick() {
    setIsAlteration(true)
    document.documentElement.scrollIntoView({ behavior: 'smooth' })
  }

  // const filterAdmins = (array: AdminProps[]) =>
  //   array.filter((user) => user.status === 'active')

  const getUsers = async (nomeUser = name, numPage = page) => {
    setMessageLoading('Carregando usuários...')
    try {
      setLoading(true)
      const response = await api.get(
        `/manager/users?page=${numPage}&limit=${limit}&status=all&name=${nomeUser}`
      )
      const { items, total } = response.data
      setNumberOfPages(Math.ceil(total / limit))
      if (Math.ceil(total / limit) === 1) {
        setPaginationOn(false)
      } else {
        setPaginationOn(true)
      }
      setUsersData(items)
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }

  useEffect(() => {
    getUsers()
  }, [page])

  const formik = useFormik({
    initialValues: defaultUserProps,
    validationSchema: isAlteration ? schemaUserChange : schemaUser,
    onSubmit: async (values) => {
      setMessageLoading('Aguarde...')
      setLoading(true)

      try {
        if (values.id !== '') {
          const response = await api.put(`/manager/user/${values?.id}`, {
            name: values.name,
            email: values.email,
            password: values.password,
            roles: values.roles,
          })
          if (response.data.status) {
            alertSuccess(response?.data?.message)
          }

          getUsers()
          formik.resetForm()
        } else {
          const response = await api.post('manager/user', {
            name: values.name,
            email: values.email,
            password: values.password,
            roles: values.roles,
            status: 'active',
          })
          if (response.data.status) {
            alertSuccess('Usuário cadastrado com sucesso')
          }
          getUsers()
          formik.resetForm()
        }
      } catch (err: any) {
        alertWarning(err.response.message || 'Não foi possível cadastrar o usu')
      }
      setTypeUser(false)
      setIsAlteration(false)
      setLoading(false)
    },
  })

  const loadUser = (id: string) => {
    setTypeUser(true)
    const userAlterar = usersData?.find((user) => user.id === id)

    formik.setValues({
      id: userAlterar!.id,
      name: userAlterar!.name,
      email: userAlterar!.email,
      roles: userAlterar!.roles,
      status: userAlterar!.status,
      password: '',
      passwordConfirmation: '',
    })
  }

  const deleteUser = async () => {
    setLoading(true)
    try {
      const response = await api.delete(`/manager/user/${userUuid}`)
      alertSuccess(response.data.message)
      getUsers()
      setLoading(false)
    } catch (err: any) {
      alertWarning(err.response.message)
      setLoading(false)
    }
  }

  const modalConfirm = () => (
    <div>
      <Modal isOpen={modal}>
        <ModalHeader toggle={toggle}>
          <h3>Deseja remover esse Usuário?</h3>
        </ModalHeader>
        <ModalFooter>
          <Row>
            <Col>
              <Button
                color="primary"
                onClick={() => {
                  toggle()
                  deleteUser()
                }}
              >
                Confirmar
              </Button>
            </Col>
            <Col>
              <Button color="secondary" onClick={toggle}>
                Cancelar
              </Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    </div>
  )

  if (loading) return <Loading title={messageLoading} />
  return (
    <>
      <HeaderNeutro />
      {modalConfirm()}
      <Container className="mt-4" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-white shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">
                      {typeUser ? 'Editar usuário' : 'Cadastrar usuário'}
                    </h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <FormikProvider value={formik}>
                  <Form onSubmit={formik.handleSubmit}>
                    <div className="pl-lg-4">
                      <Row>
                        <Col sm="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-name"
                            >
                              Nome
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-name"
                              name="name"
                              type="text"
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={formik.values.name}
                              error={formik.errors.name}
                            />
                            {formik.errors.name && formik.touched.name && (
                              <div className="input-feedback">
                                {formik.errors.name}
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                        <Col sm="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="email"
                            >
                              Email
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="email"
                              type="text"
                              name="email"
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={formik.values.email}
                              error={formik.errors.email}
                            />
                            {formik.errors.email && formik.touched.email && (
                              <div className="input-feedback">
                                {formik.errors.email}
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                        <Col sm="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="password"
                            >
                              Senha
                            </label>
                            <InputPassword
                              className="form-control-alternative"
                              id="password"
                              type="password"
                              name="password"
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={formik.values.password}
                              error={formik.errors.password}
                            />
                            {formik.errors.password &&
                              formik.touched.password && (
                                <div className="input-feedback">
                                  {formik.errors.password}
                                </div>
                              )}
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col sm="3">
                          <FormGroup>
                            <label htmlFor="roles">Tipo de usuário</label>
                            <Input
                              type="select"
                              id="roles"
                              name="roles"
                              value={formik.values.roles}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              error={formik.errors.roles}
                            >
                              <option value="">Selecione...</option>
                              <option value="SUPPORT">SUPPORT</option>
                              <option value="ADMIN">ADMIN</option>
                              <option value="SUPERADMIN">SUPERADMIN</option>
                              <option value="MARKETING">MARKETING</option>
                              <option value="SALES">SALES</option>
                              <option value="CX">CX</option>
                              <option value="COMMUNICATION">
                                COMMUNICATION
                              </option>
                            </Input>
                            {formik.errors.roles && formik.touched.roles && (
                              <div className="input-feedback">
                                {formik.errors.roles}
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                        <Col className="d-flex align-items-center " sm="4">
                          <Button
                            color="secondary"
                            onClick={() => {
                              setTypeUser(false)
                              formik.resetForm()
                              setIsAlteration(false)
                            }}
                          >
                            Resetar
                          </Button>
                          <Button
                            color="primary"
                            type="submit"
                            disabled={!formik.isValid}
                          >
                            Salvar
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </FormikProvider>
              </CardBody>
            </Card>

            <Card className="bg-white shadow mt-3">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center justify-content-between">
                  <Col sm="auto" className="justift-self-start">
                    <h3 className="mb-0 justift-self-start">Usuários</h3>
                  </Col>
                  <Col className="d-flex align-items-center justify-content-end">
                    <Col sm="4">
                      <Input
                        bsSize="sm"
                        type="text"
                        id="filtrar-name"
                        value={name}
                        placeholder="Filtrar pelo nome..."
                        onChange={(e) => setName(e.target.value)}
                      />
                    </Col>
                    <Col sm="auto">
                      <Button
                        color="secondary"
                        onClick={() => getUsers(name, 1)}
                      >
                        <i className="fas fa-search" />
                      </Button>
                    </Col>
                    <Col sm="auto">
                      <Button
                        color="primary"
                        onClick={() => {
                          setPaginationOn(true)
                        }}
                      >
                        <i className="far fa-trash-alt" />
                      </Button>
                    </Col>
                  </Col>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr d-flex="true" justify-content-center="true">
                    <th scope="col">id</th>
                    <th scope="col">nome</th>
                    <th scope="col">email</th>
                    <th scope="col">Tipo de Administrador</th>
                    <th scope="col">Status</th>
                    <th scope="col">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {usersData?.map((user) => (
                    <tr key={user.id}>
                      <th scope="row">{user.id}</th>
                      <td>{user.name}</td>
                      <td>{user.email}</td>
                      <td>{user.roles}</td>
                      <td>{user.status === 'active' ? 'Ativo' : 'Inativo'}</td>
                      <td className="d-inline-flex ">
                        <Button
                          color="default"
                          className="p-2 "
                          onClick={() => {
                            loadUser(user.id)
                            handleClick()
                          }}
                          disabled={user.status !== 'active'}
                        >
                          <i className="ni ni-ruler-pencil" />
                        </Button>
                        <Button
                          color="default"
                          className="p-2 "
                          onClick={() => {
                            setUserUuid(user.id)
                            toggle()
                          }}
                        >
                          <i className="fa fa-trash" />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {paginationOn && (
                <PaginationComponent
                  numberOfPages={numberOfPages}
                  page={page}
                  setPage={setPage}
                />
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Administer
