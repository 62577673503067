import React, { useState } from 'react'

import ScheduleCard from 'components/ScheduleCard'
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap'
import PermissionComponent from 'components/PermissionComponent'

interface Props {
  sigleQueries: any
  action: () => void
  user: any
  country: any
  // eslint-disable-next-line no-unused-vars
  handleRemove: (uuid: string) => void
}
const SingleQueries = ({
  sigleQueries,
  action,
  user,
  handleRemove,
  country,
}: Props) => {
  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)
  const [consultation, setConsultation] = useState('')
  const modalRemoveCulsultation = () => (
    <Modal isOpen={modal} toggle={toggle} style={{ maxWidth: 400 }}>
      <ModalHeader toggle={toggle}>
        <h3>Deseja excluir essa consulta avulsa?</h3>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col className="d-flex justify-content-between">
            <Button
              type="submit"
              color="primary"
              onClick={() => {
                handleRemove(consultation)
                setModal(false)
              }}
            >
              Sim
            </Button>
            <Button
              type="submit"
              color="secondary"
              onClick={() => {
                toggle()
              }}
            >
              Não
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )

  const currencySymbols = {
    BR: 'R$',
    CA: 'CA$',
    US: 'US$',
    PT: 'PT€',
  }

  const verifyPurchase =
    !user?.subscription || user?.subscription?.plan?.slug !== 'star-claro'

  return (
    <Card className="shadow d-flex">
      {modalRemoveCulsultation()}
      <CardHeader className="bg-transparent">
        <Col className="mb-0 p-0 d-flex justify-content-between">
          <h2 className="mb-0 text-black">Consultas Avulsas</h2>
          <PermissionComponent role="SUPERADMIN,ADMIN">
            {verifyPurchase && (
              <Col sm="auto" className="d-flex p-0">
                <Button
                  size="sm"
                  type="submit"
                  color="secondary"
                  title="Adicionar"
                  onClick={action}
                >
                  <i className="fas fa-plus" style={{ fontSize: 12 }} />
                </Button>
              </Col>
            )}
          </PermissionComponent>
        </Col>
      </CardHeader>
      <CardBody>
        {sigleQueries?.pending?.length !== 0 ||
        sigleQueries?.used?.length !== 0 ||
        sigleQueries?.expired?.length !== 0 ? (
          <>
            <Row>
              <Col className="mb-0 p-0">
                <h5 className=" text-black">Consultas Disponíveis</h5>
              </Col>

              {sigleQueries?.pending?.map((query: any) => (
                <Col sm="12" key={query.id} style={{ marginBottom: 10 }}>
                  <ScheduleCard
                    country={country}
                    currencySymbols={currencySymbols}
                    title={query?.product?.title}
                    quantityPaid={query?.quantity_paid}
                    unit={query?.quantity}
                    price={query.unit_price}
                    isReturned={query.is_return}
                    expired={query.expired_at}
                    returnSpecialty={query.specialty_name}
                    remove
                    action={() => {
                      setConsultation(query.id)
                      toggle()
                    }}
                  />
                </Col>
              ))}
            </Row>
            {sigleQueries?.pending?.length === 0 && (
              <Col className="mb-4">
                <h6 className=" text-black">Nenhuma consulta disponível</h6>
              </Col>
            )}
            <Row>
              <Col className="mb-0 p-0">
                <h5 className=" text-black">Consultas Utilizadas</h5>
              </Col>
              {sigleQueries?.used?.map((query: any) => (
                <Col sm="12" key={query.id} style={{ marginBottom: 10 }}>
                  <ScheduleCard
                    title={query?.product?.title}
                    isReturned={query.is_return}
                  />
                </Col>
              ))}
            </Row>
            {sigleQueries?.used?.length === 0 && (
              <Col className="mb-4">
                <h6 className=" text-black">Nenhuma consulta utilizada</h6>
              </Col>
            )}
            <Row>
              <Col className="mb-0 p-0">
                <h5 className=" text-black">Consultas Vencidas</h5>
              </Col>
              {sigleQueries?.expired?.map((query: any) => (
                <Col sm="12" key={query.id} style={{ marginBottom: 10 }}>
                  <ScheduleCard title={query?.product?.title} />
                </Col>
              ))}
            </Row>
            {sigleQueries?.expired?.length === 0 && (
              <Col className="mb-4">
                <h6 className=" text-black">Nenhuma consulta vencida</h6>
              </Col>
            )}
          </>
        ) : (
          <Col className="align-items-center" sm="12">
            <h5 className="mb-0 text-black">
              Nenhuma consulta avulsa comprada.
            </h5>
          </Col>
        )}
      </CardBody>
    </Card>
  )
}
export default SingleQueries
