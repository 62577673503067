/* eslint-disable */
import Loading from 'components/Loading'
import React, { useEffect, useMemo, useState } from 'react'
import { useFormik } from 'formik'

import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  CardHeader,
} from 'reactstrap'

import { alertSuccess, alertWarning } from 'utils/toast'
import useSpecialty from 'hooks/useSpecialty'

import * as yup from 'yup'
import {
  cpfCnpjMask,
  dateMask,
  telephoneMask,
  telephoneUnmask,
} from 'js-essentials-functions'
import { updateDoctor, updateBio } from 'services/doctor'

import api from 'configs/api'
import useFetch, { useSWRConfig } from 'hooks/useFetch'
import StatusDoctorModal from './statusDoctor'

const UseDoctor = ({ doctorData, doctorUser }: any) => {
  const [editable, setEditable] = useState(false)
  const [editableBio, setEditableBio] = useState(false)
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(false)
  const [password, setPassword] = useState(false)
  const [nameDoctor, setNameDoctor] = useState(doctorData?.name)
  const [selectLang, setSelectLang] = useState<any>('pt-BR')
  const [userDoctorBio, setUserDoctorBio] = useState<any>('')

  const { getSpecialties, filterSpecialtiesActive } = useSpecialty()
  const [statusActive, setStatusActive] = useState(
    doctorData?.status === 'active'
  )
  const [selectedLanguage, setSelectedLanguage] = useState<string[]>(
    doctorData?.countries || []
  )
  const [selectedGroups, setSelectedGroups] = useState<string[]>(
    doctorData?.groups || []
  )

  const [selectedTags, setSelectedTags] = useState<any>(
    doctorData?.tags_id || []
  )

  const [specialtiesSelect, setSpecialtiesSelect] = useState<any>(
    doctorData?.specialties?.map((specialty: any) => specialty.id) || []
  )

  const { mutate } = useSWRConfig()

  const { data: dataTags } = useFetch(`doctors/v1/psicologist-tags`)
  const { data } = useFetch(`accounts/v1/countries`)
  const { data: dataBio } = useFetch(`manager/doctors/${doctorData?.id}/bio`)
  const { data: dataGroups } = useFetch(`doctors/ms/v1/plan-groups`)

  const countries = data?.map((pais: any) => pais?.sigla)
  const filteredCountriesUS = countries?.filter(
    (country: any) => country !== 'BR'
  )

  const filteredCountries = () => {
    if (selectedLanguage?.includes('BR') && selectedLanguage?.includes('US')) {
      return countries
    }
    if (selectedLanguage?.includes('BR')) {
      return ['PT', 'BR']
    }
    if (selectedLanguage?.includes('US')) {
      return filteredCountriesUS
    }

    return []
  }

  const handleCountryChange = (e: any) => {
    const selectedLanguage = e.target.value
    setSelectedLanguage((prevLanguages: any) => {
      if (prevLanguages?.includes(selectedLanguage)) {
        return prevLanguages.filter((lang: any) => lang !== selectedLanguage)
      }

      if (prevLanguages.length === 2) {
        return [prevLanguages[1], selectedLanguage]
      }
      return [...prevLanguages, selectedLanguage]
    })
  }

  const handleTagSelection = (tagId: any) => {
    const index = selectedTags.indexOf(tagId)
    if (index === -1 && selectedTags.length < 4) {
      setSelectedTags([...selectedTags, tagId])
    } else if (index !== -1) {
      setSelectedTags(selectedTags.filter((id: any) => id !== tagId))
    }
  }

  const handleSpecialtyChange = (specialtyId: any) => {
    setSpecialtiesSelect((prevSpecialties: any) => {
      if (prevSpecialties.includes(specialtyId)) {
        return prevSpecialties.filter((id: any) => id !== specialtyId)
      }
      return [...prevSpecialties, specialtyId]
    })
  }

  const handleGroupChange = (group: string) => {
    setSelectedGroups((prevGroups) => {
      const newGroups = prevGroups.includes(group)
        ? prevGroups.filter((id) => id !== group)
        : [...prevGroups, group]
      return newGroups
    })
  }

  const schemaRegister = yup.object().shape({
    name: yup.string().required('O campo é obrigatório.'),
    email: yup
      .string()
      .email('E-mail inválido.')
      .required('O campo é obrigatório.'),
    cellphone: yup.string().required('O campo é obrigatório.'),
    gender: yup.string().required('O campo é obrigatório.'),
  })

  const initialValues = useMemo(
    () => ({
      name: '',
      document: '',
      birthDay: '',
      email: '',
      cellphone: '',
      gender: '',
      crm: '',
      tags: [''],
      specialties: [''],
    }),
    []
  )
  const genders = [
    {
      label: 'Masculino',
      key: 'male',
    },
    {
      label: 'Feminino',
      key: 'female',
    },
  ]

  const formik = useFormik({
    initialValues,
    validationSchema: schemaRegister,
    onSubmit: async (values) => {
      setLoading(true)
      const userData = {
        name: values.name.trim(),
        email: values.email,
        cellphone: telephoneUnmask(values.cellphone),
        birthdate: values.birthDay,
        gender: values.gender === '' ? null : values.gender,
        countries: filteredCountries(),
        tags: selectedTags,
        specialties: specialtiesSelect?.map((specialty: any) => ({
          id: specialty,
        })),
        groups: selectedGroups,
      }

      const response = await updateDoctor({ id: doctorData?.id, userData })

      if (response.status) {
        setNameDoctor(values?.name)
        alertSuccess(response.message)
        setLoading(false)
        setEditable(false)
        doctorUser()
      } else {
        alertWarning(response.message)
      }
    },
  })

  const passwordTemporary = async () => {
    try {
      await api.patch(`manager/doctors/${doctorData?.id}/reset-password`)

      alertSuccess('Senha temporária enviada com sucesso!')
      setPassword(false)
    } catch (error) {
      alertWarning('Senha temporária não enviada, tente novamente.')
    }
  }

  const setDataUser = () => {
    const { name, email, profiles, tags_id } = doctorData
    formik.setValues({
      name: name || '',
      document: profiles?.document || '',
      birthDay: profiles?.birthdate || '',
      email: email || '',
      cellphone: profiles?.cellphone || '',
      gender: profiles?.gender || '',
      crm:
        `${profiles?.professional_document_number} ${profiles?.professional_document_type}` ||
        '',
      tags: tags_id || [],
      specialties: specialtiesSelect,
    })
  }

  const handleBio = async () => {
    setLoading(true)
    try {
      const res = await updateBio({
        id: doctorData?.id,
        bio: userDoctorBio,
        lang: selectLang,
      })
      mutate(`manager/doctors/${doctorData?.id}/bio`)
      alertSuccess(res?.message)
      setEditableBio(false)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setDataUser()
    getSpecialties()
  }, [doctorData])

  const getBio = (lang: string) => {
    const selectedBio = dataBio?.find((bio: any) => bio.lang === lang)
    if (selectedBio) {
      setUserDoctorBio(selectedBio.bio)
    }
  }

  useEffect(() => {
    getBio(selectLang)
  }, [dataBio])

  const toggle = () => setPassword(!password)

  const modalPassword = () => (
    <Modal isOpen={password} toggle={toggle} style={{ maxWidth: 400 }}>
      <ModalHeader toggle={toggle}>
        <h3>Deseja enviar uma senha provisória para esse profissional?</h3>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col className="d-flex justify-content-between">
            <Button type="button" color="secondary" onClick={toggle}>
              Cancelar
            </Button>
            <Button
              type="button"
              color="primary"
              onClick={() => {
                passwordTemporary()
                setModal(false)
              }}
            >
              Confirmar
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )

  return (
    <>
      {loading && <Loading title="Carregando..." />}
      {modalPassword()}
      <Form onSubmit={formik.handleSubmit}>
        <Card className="shadow">
          <CardHeader className="d-flex align-items-center">
            <Col sm="8">
              <h3>{`${nameDoctor || doctorData.name}`}</h3>
            </Col>
            <Col sm="4" className="d-flex p-0 ml-2">
              <Button
                color="primary"
                onClick={() => setPassword(true)}
                title="Ativar"
              >
                Enviar Senha Provisória
              </Button>
            </Col>
          </CardHeader>
          <StatusDoctorModal
            setModal={setModal}
            modal={modal}
            setLoading={setLoading}
            statusActive={statusActive}
            setStatusActive={setStatusActive}
            doctorData={doctorData}
          />
          <CardBody
            style={{ backgroundColor: '#F8FeF9' }}
            className="pb-0 pr-0"
          >
            <h6 className="heading-small text-muted ">
              Informações do profissional da saúde
            </h6>
            <div className="pl-lg-4" style={{ padding: '2rem 2rem 0 0' }}>
              <Row className="d-flex ">
                <Col md="6  ">
                  <FormGroup>
                    <Label for="name" style={{ fontSize: 14 }}>
                      Nome
                    </Label>
                    <Input
                      className="form-control-alternative"
                      id="input-username"
                      name="name"
                      type="text"
                      disabled={!editable}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik?.values?.name || ''}
                      error={formik.errors?.name}
                    />
                  </FormGroup>
                </Col>

                <Col md="6 ">
                  <FormGroup>
                    <Label for="name" style={{ fontSize: 14 }}>
                      Email
                    </Label>
                    <Input
                      className="form-control-alternative"
                      id="input-username"
                      name="email"
                      type="text"
                      disabled={!editable}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values?.email || ''}
                      error={formik.errors.email}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </div>
            <div className="pl-lg-4" style={{ padding: '0 2rem 0 0' }}>
              <Row className="d-flex">
                <Col md="6 ">
                  <FormGroup>
                    <Label for="document" style={{ fontSize: 14 }}>
                      CPF
                    </Label>
                    <Input
                      className="form-control-alternative"
                      id="input-username"
                      name="document"
                      type="text"
                      maxLength={14}
                      disabled
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={cpfCnpjMask(formik.values?.document || '')}
                      error={formik.errors.document}
                    />
                  </FormGroup>
                </Col>
                <Col md="6 ">
                  <FormGroup>
                    <Label for="crm" style={{ fontSize: 14 }}>
                      Conselho de Classe
                    </Label>
                    <Input
                      className="form-control-alternative"
                      id="input-crm"
                      name="crm"
                      type="text"
                      disabled
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values?.crm || ''}
                      error={formik.errors.crm}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row className="d-flex">
                <Col sm="6">
                  <FormGroup
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                  >
                    <label
                      className="form-control-label"
                      htmlFor="input-status"
                    >
                      Status
                    </label>
                    <label className="custom-toggle">
                      <input
                        type="checkbox"
                        checked={statusActive}
                        onClick={() => {
                          setModal(true)
                        }}
                      />
                      <span className="custom-toggle-slider rounded-circle" />
                    </label>
                  </FormGroup>
                </Col>
              </Row>
            </div>
            <div
              style={{
                borderBottom: '1px solid #e6e1f1',
                padding: '1rem ',
                width: '97.5%',
              }}
            >
              {' '}
            </div>
          </CardBody>
          <CardBody
            style={{ backgroundColor: '#F7FeF9', padding: '2rem ' }}
            className="pb-0 pr-0 "
          >
            <h6 className="heading-small text-muted ">
              Informações do profissional da saúde
            </h6>
            <div className="pl-lg-4" style={{ padding: '2rem 2rem 0 0' }}>
              <Row className="d-flex">
                <Col lg="4 mt-3">
                  <FormGroup>
                    <label htmlFor="gender">Gênero</label>

                    <Input
                      type="select"
                      id="gender"
                      name="gender"
                      disabled={!editable}
                      value={formik.values?.gender || ''}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <option label="Gênero" />
                      {genders.map((e) => (
                        <option key={e.key} value={e.key} label={e.label} />
                      ))}
                    </Input>
                  </FormGroup>
                </Col>
                <Col lg="4 mt-3">
                  <FormGroup>
                    <Label for="birthDay" style={{ fontSize: 14 }}>
                      Data de nascimento
                    </Label>
                    <Input
                      className="form-control-alternative"
                      id="input-username"
                      name="birthDay"
                      type="text"
                      maxLength={10}
                      disabled={!editable}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={dateMask(formik.values?.birthDay || '')}
                      error={formik.errors.birthDay}
                    />
                  </FormGroup>
                </Col>
                <Col lg="4 mt-3">
                  <FormGroup>
                    <Label for="name" style={{ fontSize: 14 }}>
                      Celular
                    </Label>
                    <Input
                      className="form-control-alternative"
                      id="input-cellphone"
                      type="text"
                      name="cellphone"
                      maxLength={15}
                      disabled={!editable}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={telephoneMask(formik.values.cellphone || '')}
                      error={formik.errors.cellphone}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col lg="auto">
                  <h3 className="form-control-label">Área de atuação</h3>
                  <div className="overflow-auto d-flex ">
                    <FormGroup className="d-flex flex-wrap ml-4">
                      {filterSpecialtiesActive().map((specialty) => (
                        <Label
                          className="mr-5 align-items-center justify-content-center"
                          size="sm"
                          key={specialty.id}
                        >
                          <Input
                            name="specialties"
                            type="checkbox"
                            className="mr-2 align-items-center justify-content-center"
                            checked={specialtiesSelect.includes(specialty.id)}
                            onBlur={formik.handleBlur}
                            onChange={() => handleSpecialtyChange(specialty.id)}
                            value={specialty.id}
                            disabled={!editable}
                          />
                          {specialty.name}
                        </Label>
                      ))}
                    </FormGroup>
                  </div>
                </Col>
              </Row>
              {dataGroups?.length > 0 && (
                <Row>
                  <Col lg="auto">
                    <h3 className="form-control-label">
                      Formato de atendimento
                    </h3>
                    <FormGroup className="d-flex flex-wrap ml-4">
                      {dataGroups.map((item: any) => (
                        <Label
                          key={item}
                          className="mr-5 align-items-center justify-content-center"
                          size="sm"
                        >
                          <Input
                            name="groups"
                            type="checkbox"
                            className="mr-2 align-items-center justify-content-center"
                            value={item}
                            checked={selectedGroups.includes(item)}
                            onBlur={formik.handleBlur}
                            onChange={() => handleGroupChange(item)}
                            disabled={!editable}
                          />
                          {item}
                        </Label>
                      ))}
                    </FormGroup>
                  </Col>
                </Row>
              )}
              {data && (
                <Row>
                  <Col lg="auto">
                    <h3 className="form-control-label">Idiomas atendidos</h3>
                    <FormGroup className="d-flex flex-wrap ml-4">
                      <Label
                        className="mr-5 align-items-center justify-content-center"
                        size="sm"
                      >
                        <Input
                          name="countries"
                          type="checkbox"
                          className="mr-2 align-items-center justify-content-center"
                          checked={selectedLanguage?.includes('BR')}
                          disabled={!editable}
                          onBlur={formik.handleBlur}
                          onChange={(e) => handleCountryChange(e)}
                          value="BR"
                        />
                        Português
                      </Label>
                      <Label
                        className="mr-5 align-items-center justify-content-center"
                        size="sm"
                      >
                        <Input
                          name="countries"
                          type="checkbox"
                          className="mr-2 align-items-center justify-content-center"
                          checked={selectedLanguage?.includes('US')}
                          disabled={!editable}
                          onBlur={formik.handleBlur}
                          onChange={(e) => handleCountryChange(e)}
                          value="US"
                        />
                        Inglês
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
              )}

              {doctorData?.profiles?.professional_document_type === 'CRP' && (
                <>
                  <Row>
                    <Col lg="auto">
                      <h3 className="form-control-label">
                        Especialidade (máx. 4)
                      </h3>
                      <FormGroup className="d-flex flex-wrap ml-4">
                        {dataTags &&
                          dataTags?.map((tag: any) => {
                            const isDisabled =
                              selectedTags?.length >= 4 &&
                              !selectedTags.includes(tag.id)

                            return (
                              <Label
                                className="mr-5 align-items-center justify-content-center"
                                size="sm"
                                key={tag.id}
                              >
                                <Input
                                  name="tags"
                                  type="checkbox"
                                  className="mr-2 align-items-center justify-content-center"
                                  value={tag.id}
                                  checked={selectedTags?.includes(tag.id)}
                                  onBlur={formik.handleBlur}
                                  onChange={() => handleTagSelection(tag.id)}
                                  disabled={!editable && !isDisabled}
                                />
                                {tag.title}
                              </Label>
                            )
                          })}
                      </FormGroup>
                    </Col>
                  </Row>
                </>
              )}

              <Row
                className="d-flex justify-content-end"
                style={{ padding: '0 0 3rem 0' }}
              >
                <Col lg="3">
                  {editable && (
                    <Button type="submit" color="secondary" title="Salvar">
                      <i className="" style={{ fontSize: 16 }} />
                      Salvar
                    </Button>
                  )}
                  {!editable && (
                    <Button
                      type="button"
                      color="secondary"
                      onClick={() => setEditable(true)}
                      title="Editar"
                    >
                      <i className="" style={{ fontSize: 16 }} />
                      Editar
                    </Button>
                  )}
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>
      </Form>

      <Form className="mt-4">
        <Card>
          <CardBody style={{ backgroundColor: '#F7FeF9', padding: '2rem' }}>
            <h6 className="heading-small text-muted ">Bio</h6>
            <Row className="d-flex">
              <Col lg="4">
                <FormGroup>
                  <Input
                    type="select"
                    id="language"
                    name="language"
                    disabled={!editableBio}
                    onChange={(e) => {
                      getBio(e.target.value)
                      setSelectLang(e.target.value)
                    }}
                    value={selectLang}
                  >
                    <option value="pt-BR" label="Português" />
                    <option value="en-US" label="Inglês" />
                    <option value="es-ES" label="Espanhol" />
                  </Input>
                </FormGroup>
              </Col>
              <Col lg="12">
                <FormGroup>
                  <Input
                    className="form-control-alternative"
                    id="input-bio"
                    name="bio"
                    type="textarea"
                    disabled={!editableBio}
                    onChange={(e) => {
                      setUserDoctorBio(e.target.value)
                    }}
                    value={userDoctorBio}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row className=" d-flex justify-content-end">
              <Col lg="4" className="mb-4 d-flex align-items-center ">
                {editableBio && (
                  <Button
                    color="secondary"
                    title="Salvar"
                    disabled={!editableBio}
                    onClick={handleBio}
                  >
                    Salvar Bio
                  </Button>
                )}
                {!editableBio && (
                  <Button
                    type="button"
                    color="secondary"
                    onClick={() => setEditableBio(true)}
                    title="Editar"
                  >
                    <i className="" style={{ fontSize: 16 }} />
                    Editar
                  </Button>
                )}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Form>
    </>
  )
}

export default UseDoctor
