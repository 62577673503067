import React, { useState } from 'react'
import classnames from 'classnames'
import {
  Card,
  Container,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap'
import HeaderNeutro from 'components/Headers/HeaderNeutro'
import Audios from './Tabs/Audios'
import Temas from './Tabs/Temas'

const Starzen = () => {
  const [tabs, setTabs] = useState(1)

  return (
    <div>
      <HeaderNeutro />
      <Container fluid>
        <div className="nav-wrapper">
          <Nav
            className="flex-column flex-md-row"
            id="tabs-icons-text"
            pills
            role="tablist"
          >
            <NavItem>
              <NavLink
                aria-selected={tabs === 1}
                className={classnames(
                  'mb-sm-3 mb-md-0 d-flex justify-content-center align-items-center',
                  {
                    active: tabs === 1,
                  }
                )}
                onClick={() => setTabs(1)}
                href="#audios"
                style={{ width: '248px', height: '44px' }}
              >
                <i className="fas fa-volume-up mr-2" />
                Audios
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                aria-selected={tabs === 2}
                className={classnames(
                  'mb-sm-3 mb-md-0 d-flex justify-content-center align-items-center',
                  {
                    active: tabs === 2,
                  }
                )}
                onClick={() => setTabs(2)}
                href="#Temas"
                style={{ width: '248px', height: '44px' }}
              >
                <i className="fas fa-palette mr-2" />
                Temas
              </NavLink>
            </NavItem>
          </Nav>
        </div>
        <Card className="shadow">
          <CardBody className="p-0">
            <TabContent activeTab={`tabs${tabs}`}>
              <TabPane tabId="tabs1">
                <Audios />
              </TabPane>
              <TabPane tabId="tabs2">
                <Temas />
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}

export default Starzen
