import React from 'react'
import './styles.css'

type IModal = {
  children: any
}

const Modal: React.FC<IModal> = ({ children }) => (
  <>
    <div className="sb--modal d-flex align-items-center justify-content-center">
      <div className="sb--modal--content">{children}</div>
    </div>
  </>
)

export default Modal
