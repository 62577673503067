import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import {
  UserData,
  getSeparateConsultation,
  createConsultation,
} from 'services/clients'
import { alertWarning, alertSuccess } from 'utils/toast'
import classnames from 'classnames'

import api from 'configs/api'
import useFetch from 'hooks/useFetch'

import {
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Input,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap'

import HeaderNeutro from 'components/Headers/HeaderNeutro'
import Loading from 'components/Loading'

import {
  Historic,
  Plans,
  DataStar,
  SingleQueries,
  ScheduleAppointment,
} from './Components'

const itemsTabs = [
  {
    id: 1,
    title: 'Dados',
    icon: 'ni ni-settings-gear-65',
    active: true,
  },
  {
    id: 2,
    title: 'Planos',
    icon: 'ni ni-settings-gear-65',
    active: true,
  },
  {
    id: 3,
    title: 'Histórico Consultas',
    icon: 'fas fa-book',
    active: true,
  },
]

const Star = () => {
  const [loading, setLoading] = useState(false)
  const [userData] = useState<UserData>({} as UserData)
  const [separate, setSeparate] = useState<any>()
  const [specialty, setSpecialty] = useState('')
  const [modal, setModal] = useState(false)
  const [tabs, setTabs] = useState(1)
  const { pathname }: any = useLocation()
  const { push } = useHistory()

  const userId = pathname.split('/')[pathname.split('/').length - 1]

  const { data, isLoading } = useFetch(`accounts/ms/v1/user/${userId}/all-info`)
  const { data: sigleQueries } = useFetch(`/manager/client-infos/${userId}`)

  const toggle = () => setModal(!modal)

  const separateConsultation = async () => {
    setLoading(true)
    const response = await getSeparateConsultation()
    if (response.status) {
      setLoading(false)
      setSeparate(response.data)
    } else {
      setLoading(false)
    }
  }

  useEffect(() => {
    separateConsultation()
  }, [userId])

  const handleConfirm = async () => {
    setLoading(true)
    const res = await createConsultation(userId, specialty)
    if (res.status) {
      setSpecialty('')
      alertSuccess('Consulta Avulsa concedida com sucesso.')
    } else {
      alertWarning('Ocorreu algum erro ao conceder a consulta.')
    }
    toggle()
    setLoading(false)
  }
  const handleRemove = async (consultation: string) => {
    setLoading(true)
    try {
      await api.delete(`manager/separate-consultation/${consultation}`)
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }
  const handleActiveUser = async (id: string) => {
    setLoading(true)
    try {
      await api.patch(`manager/client/${id}/status`)
      setLoading(false)
      alertSuccess('Usuário ativado com sucesso.')
    } catch (err) {
      alertWarning('Erro ao ativar usuário.')
      setLoading(false)
    }
  }

  const handlePasswordTempory = async (id: string) => {
    setLoading(true)
    try {
      await api.post(`manager/client/${id}/password-temporary`)
      setLoading(false)
      alertSuccess('Senha enviada.')
    } catch (err) {
      alertWarning('Erro ao enviar senha.')
      setLoading(false)
    }
  }

  const modalCulsultationfree = () => (
    <Modal isOpen={modal} toggle={toggle} style={{ maxWidth: 400 }}>
      <ModalHeader toggle={toggle}>
        <h3>Deseja conceder uma consulta avulsa de qual especialidade?</h3>
      </ModalHeader>
      <ModalBody>
        <Row className="mb-4 d-flex align-items-center justify-content-center">
          <Col sm="12">
            <Input
              type="select"
              id="selectTypeDoc"
              name="selectTypeDoc"
              onChange={(e) => {
                setSpecialty(e.target.value)
              }}
              value={specialty}
            >
              <option selected>Selecione...</option>
              {separate?.map((p: any) => (
                <option key={p.id} value={p.title}>
                  {p.title}
                </option>
              ))}
            </Input>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-between">
            <Button
              type="submit"
              color="primary"
              disabled={!specialty}
              onClick={handleConfirm}
            >
              Confirmar
            </Button>
            <Button
              type="submit"
              color="secondary"
              onClick={() => {
                setSpecialty('')
                toggle()
              }}
            >
              Cancelar
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )

  return (
    <div>
      {modalCulsultationfree()}
      <HeaderNeutro />
      {(loading || isLoading) && <Loading title="Carregando..." />}
      <Container className="mt-3" fluid>
        <Row className="mt-3 align-items-end d-flex justify-content-between">
          <Col sm="auto" className="align-items-start ">
            <Button
              color="secondary"
              onClick={() => {
                push('/admin/estrelas')
              }}
            >
              <i className="fas fa-arrow-left" />
            </Button>
          </Col>
        </Row>
      </Container>

      <Container fluid>
        <div className="nav-wrapper">
          <Nav
            className="nav-fill flex-column flex-md-row"
            id="tabs-icons-text"
            pills
            role="tablist"
          >
            {itemsTabs.map((item) => (
              <NavItem key={item.id}>
                <NavLink
                  aria-selected={tabs === item.id}
                  className={classnames('mb-sm-3 mb-md-0', {
                    active: tabs === item.id,
                  })}
                  disabled={!item.active}
                  onClick={() => setTabs(item.id)}
                  style={!item.active ? { opacity: 0.7 } : {}}
                  href={`#${item.title}`}
                  role="tab"
                >
                  <i className={`${item.icon} mr-2`} />
                  {item.title}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
        </div>
        <TabContent activeTab={`tabs${tabs}`}>
          <TabPane tabId="tabs1">
            <Row>
              <Col className="order-xl-2 mb-2 mb-xl-0" xl="8">
                <DataStar
                  uuid={userId}
                  user={data}
                  handleActiveUser={handleActiveUser}
                  handlePasswordTempory={handlePasswordTempory}
                />
              </Col>
              <Col className="order-xl-2 mb-2 mb-xl-0" xl="4">
                <ScheduleAppointment user={data} country={data?.country} />
                <SingleQueries
                  sigleQueries={sigleQueries?.item?.separate_consultation}
                  action={toggle}
                  user={userData?.plan}
                  handleRemove={handleRemove}
                  country={data?.country}
                />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="tabs2">
            <Plans userId={data?.id} />
          </TabPane>
          <TabPane tabId="tabs3">
            <Historic setLoading={setLoading} userId={userId} />
          </TabPane>
        </TabContent>
      </Container>
    </div>
  )
}

export default Star
