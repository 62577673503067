/* eslint-disable radix */
import React, { useState } from 'react'
import {
  Button,
  CardHeader,
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  CardText,
  FormGroup,
  InputGroupAddon,
  InputGroup,
  InputGroupText,
} from 'reactstrap'
import moment from 'moment'
import ReactDatetime from 'react-datetime'
import { useExcelDownloder } from 'react-xls'

import api from 'configs/api'
import Loading from 'components/Loading'
import { alertSuccess, alertDanger } from 'utils/toast'

const Reports = () => {
  const { ExcelDownloder, Type } = useExcelDownloder()
  const [loading, setLoading] = useState(false)
  const [dates, setDates] = useState({
    start: '',
    end: '',
  })

  const [download, setDownload] = useState<any>({
    id: 0,
    data: null,
  })

  const getReport = async () => {
    const { start } = dates
    const formattedDate = moment(dates.start, 'DD/MM/YYYY').format('YYYY-MM')

    if (!start) {
      return alertDanger('Para acessar, preencha os períodos!')
    }
    setLoading(true)
    try {
      const { data } = await api.get(
        `manager/reports/financial/companies?month=${formattedDate}`
      )

      const companies = data.companies.map((item: any) => ({
        Empresa: item.legal_nature,
        CNPJ: item.national_registration,
        'Data do vencimento': item.due_day,
        'Data de assinatura do contrato': item.contract_date,
        Titulares: parseInt(item.active_holder),
        Dependentes: parseInt(item.active_dependents),
        'Titulares excluídos após dia 5 do mês': parseInt(item.deleted_holder),
        'Dependentes excluídos após dia 5 do mês': parseInt(
          item.deleted_dependents
        ),
        'Mínimo de vidas': parseInt(item.min_lives),
        'Número de vidas': parseInt(item.total),

        'Plano Starbem (Titular)': item.amount,
        'Valor total de mínimo de vidas': item.amount_min_lives,
        'Valor total de vidas': item.amount_total,
        'Valor do plano Starbem (Dependente)': item.amount_dependent,
        'Valor Gympass': item.amount_gympass,
        'Valor TotalPass': item.amount_totalpass,
      }))

      if (data.companies.length > 0) {
        setDownload({
          id: 1,
          data: {
            empresas: companies,
          },
        })
        alertSuccess(
          'Sucesso!! Para fazer o download, clique no botão "baixar"'
        )
      }
    } catch (error) {
      alertDanger('Ocorreu algum erro')
    }
    setLoading(false)
    return true
  }

  return (
    <div>
      {loading && <Loading title="Aguarde..." />}
      <CardHeader
        className="bg-transparent border-0"
        style={{ minHeight: 100 }}
      >
        <Row className="mt-3 align-items-center d-flex justify-content-between">
          <Col sm="auto" className="mb-2">
            <h3 className="mb-0 text-black">Relatórios | Financeiro</h3>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row className="align-items-center">
          <Col sm="auto">
            <p style={{ fontWeight: 600 }}>Período:</p>
          </Col>
          <Col sm={4}>
            <FormGroup>
              <h5 className="mb-2 text-black">Data inicial</h5>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-calendar-grid-58" />
                  </InputGroupText>
                </InputGroupAddon>
                <ReactDatetime
                  inputProps={{
                    placeholder: 'Selecione o mês e ano',
                  }}
                  dateFormat="MM/YYYY"
                  timeFormat={false}
                  onChange={(e: any) =>
                    setDates({
                      ...dates,
                      start: moment(e).format('DD/MM/YYYY'),
                    })
                  }
                />
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
        <Col sm={3} className="mb-3">
          <Card>
            <CardBody>
              <CardTitle className="mb-1 text">
                Vencimento e valores dos planos
              </CardTitle>
              <CardText className="text-sm">
                Gera o relatório das empresas com os valores e data de
                vencimento conforme escolhido.
              </CardText>
              <Row>
                <Col>
                  {download.data && (
                    <ExcelDownloder
                      filename="Relatório financeiro"
                      data={download.data}
                      type={Type}
                    >
                      <Button
                        size="sm"
                        outline
                        color="primary"
                        onClick={() => setDownload({ id: 0, data: null })}
                      >
                        <i className="fas fa-download mr-2" />
                        Baixar
                      </Button>
                    </ExcelDownloder>
                  )}
                </Col>
                <Col className="d-flex justify-content-end">
                  <Button
                    size="sm"
                    onClick={() => {
                      getReport()
                    }}
                  >
                    Acessar Relatório
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </CardBody>
    </div>
  )
}

export default Reports
