import React, { useState, useEffect } from 'react'
import { cpfCnpjMask } from 'js-essentials-functions'

import {
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  Input,
  Button,
} from 'reactstrap'
import api from 'configs/api'
import { alertWarning, alertSuccess } from 'utils/toast'

const ModalCompanies = ({
  modal,
  setModal,
  userId,
  setLoading,
  action,
}: any) => {
  const [companies, setCompanies] = useState<any[]>()
  const [company, setCompany] = useState('')
  const [name, setName] = useState('')

  const toggle = () => {
    setCompany('')
    setName('')
    setModal(!modal)
  }

  const getCompanies = async () => {
    try {
      const response = await api.get(
        `manager/companies?page=1&limit=10&name=${name}&status=all`
      )
      const datas = response.data.items.map((data: any) => ({
        id: data.id,
        name: data.legal_nature,
        status: data.status,
        cnpj: data.national_registration,
        total: data.total_users,
        logo: data.logo,
      }))

      setCompanies(datas)
    } catch (err) {
      console.log(err)
    }
  }
  const changeCompany = async () => {
    setLoading(true)
    try {
      await api.post(`manager/companies/user/change-company`, {
        company_id: company,
        user_id: userId,
      })
      alertSuccess('Estrela alterada com sucesso!')
      toggle()
      action()
    } catch (err) {
      alertWarning('Ocorreu um erro ao mudar estrela')
      console.log(err)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (!name || name.length > 2) {
      getCompanies()
    }
  }, [name])

  return (
    <Modal isOpen={modal} toggle={toggle} style={{ minWidth: 500 }}>
      <ModalHeader toggle={toggle}>
        <div>
          <h3>Deseja alterar a estrela para qual empresa?</h3>
        </div>
      </ModalHeader>

      <ModalBody className="p-4">
        <Row>
          <Col>
            <Input
              type="text"
              id="filtrar-name"
              maxLength={14}
              value={name}
              placeholder="Filtrar pelo nome..."
              onChange={(e) => setName(e.target.value)}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            {companies?.map((item) => (
              <div key={item.id} className="custom-control custom-radio mb-3">
                <input
                  className="custom-control-input"
                  id={item.id}
                  name="custom-radio-2"
                  type="radio"
                  value={item.id}
                  onChange={(e) => setCompany(e.target.value)}
                />
                <label className="custom-control-label" htmlFor={item.id}>
                  {`${item.name} -- ${cpfCnpjMask(item.cnpj || '')}`}
                </label>
              </div>
            ))}
          </Col>
        </Row>
        <Row className="mt-3">
          <Col className="d-flex justify-content-end">
            <Button
              color="secondary"
              disabled={!company}
              onClick={() => changeCompany()}
            >
              Alterar
            </Button>
            <Button outline color="primary" onClick={toggle}>
              Cancelar
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}
export default ModalCompanies
