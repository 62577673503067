import React, {
  createContext,
  FunctionComponent,
  useCallback,
  useContext,
  useState,
} from 'react'

import { getStorage, setStorage, clearStorage } from 'utils/storage'
import api from 'configs/api'
import { AdminProps, AuthContextState } from './authentication.types'

const AuthContext = createContext<AuthContextState>({} as AuthContextState)

const defaultAdmin: AdminProps = {
  id: '',
  name: '',
  email: '',
  roles: '',
  status: '',
}

const AuthProvider: FunctionComponent = ({ children }) => {
  const [tokenAdmin, setTokenAdmin] = useState('')
  const [adminData, setAdminData] = useState<AdminProps>(defaultAdmin)

  const signInSuccess = useCallback((token: string): void => {
    setStorage('@ManagerStarbem:token', `${token}`)
    setTokenAdmin(token)
  }, [])

  const userLogged = useCallback(() => {
    const token = getStorage('@ManagerStarbem:token')
    if (token) {
      return true
    }
    return false
  }, [])

  const logout = () => {
    clearStorage()
    setTokenAdmin('')
  }

  const verifyAdminStorage = (): void => {
    const data = getStorage('@ManagerStarbem:user')
    if (data) {
      setAdminData(data)
    }
  }

  const verifyUser = async () => {
    const data = getStorage('@ManagerStarbem:user')

    if (data?.id) {
      try {
        const { data: user } = await api.get(`manager/user/${data.id}/me`)

        if (user?.user?.status === 'inactive') {
          logout()
        }
      } catch (e) {
        logout()
      }
    }
  }

  return (
    <AuthContext.Provider
      value={{
        token: tokenAdmin,
        adminData,
        logout,
        userLogged,
        setAdminData,
        signInSuccess,
        verifyAdminStorage,
        verifyUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const useAuth = (): AuthContextState => useContext(AuthContext)

export { AuthProvider, useAuth }
