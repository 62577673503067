/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback } from 'react'
import { cpfCnpjMask, cpfValidator } from 'js-essentials-functions'

import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap'
import api from 'configs/api'
import PaginationComponent from 'components/PaginationComponent'
import { useDropzone } from 'react-dropzone'
import { alertWarning, alertSuccess } from 'utils/toast'
import HeaderNeutro from 'components/Headers/HeaderNeutro'
import Loading from 'components/Loading'
import ModalContent from 'components/Modal'
import ReactDropZoneInput from 'components/ReactDropzoneInput'
import { useHistory, useLocation } from 'react-router-dom'
import moment from 'moment'
import NewUserB2b from './Components/ModalCreateUser'

const UsersCompany = () => {
  const [usesCompany, setUsersCompany] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [name, setName] = useState('')
  const [nameCompany, setNameCompany] = useState('')
  const [fileToUpload, setFileToUpload] = useState<any | null>(null)
  const [repeatedUsers, setRepeatedUsers] = useState([])
  const [modal, setModal] = useState(false)
  const [usersinvalid, setUsersInvalid] = useState<any[]>([])

  const [showModal, setShowModal] = useState(false)
  const [modalRemove, setModalRemove] = useState(false)
  const [userRemove, setUserRemove] = useState('')

  const params: any = useLocation()
  const navigation = useHistory()

  const companyUuid =
    params.pathname.split('/')[params.pathname.split('/').length - 1]

  // Paginação
  const [paginationOn, setPaginationOn] = useState(true)
  const [numberOfPages, setNumberOfPages] = useState(0)
  const [page, setPage] = useState(1)
  const limit = 10
  const [plans, setPlans] = useState<Array<any>>([])
  const [selectedPlan, setSelectedPlan] = useState<string>('0')
  const [typeUser, setTypeUser] = useState<
    'titular' | 'dependente' | 'remove' | ''
  >('')

  // react-dropzone config
  const onDrop = useCallback(
    async (acceptedFiles) => {
      await acceptedFiles.forEach((file: any) => {
        const reader = new FileReader()
        reader.onload = async () => {
          const binaryStr = reader.result
          const csvString = binaryStr?.toString()

          if (csvString) {
            const lines = csvString.split('\n')
            const users: string[] = []
            lines.map((line: string, index: number) => {
              if (index > 0) {
                const result = line.split(',')
                if (typeUser === 'titular' && !!result[0]) {
                  if (
                    !cpfValidator(result[2]) ||
                    !moment(result[1], 'DD/MM/YYYY', true).isValid()
                  ) {
                    users.push(result[0])
                  }
                }
                if (typeUser === 'dependente' && !!result[0]) {
                  if (
                    !cpfValidator(result[3]) ||
                    !moment(result[2], 'DD/MM/YYYY', true).isValid()
                  ) {
                    users.push(result[0])
                  }
                }
              }
            })
            setUsersInvalid(users)
          }
        }
        reader.readAsText(file)
      })

      setFileToUpload(acceptedFiles[0])
    },
    [typeUser]
  )

  const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
    useDropzone({
      onDrop,
      accept: '.csv, application/vnd.ms-excel, text/csv',
      multiple: false,
    })

  useEffect(() => {
    const getPlans = async () => {
      try {
        if (nameCompany) {
          const res = await api.get(`manager/plans?name=empresa`)
          setPlans(res.data.items)
        }
      } catch (err) {
        console.log(err)
      }
    }

    getPlans()
  }, [usesCompany])

  const getCompany = async (nameUser = name, numberPage = page) => {
    setName('')
    setLoading(true)
    try {
      const response = await api.get(
        `/manager/companies/${companyUuid}?embed=users&page=${numberPage}&limit=${limit}&user_status=active&user_name=${nameUser}`
      )
      setNameCompany(response.data.item.legal_nature)
      const datas = response.data.item.users.map((data: any) => ({
        id: data.id,
        name: data.name,
        document: data.document,
        status: data.status === 'active' ? 'Ativo' : 'Inativo',
        email: data.email,
        is_holder: data.is_holder,
      }))

      if (
        Math.ceil(response.data.totalUsers / limit) === 1 ||
        datas.length === 0
      ) {
        setPaginationOn(false)
      } else {
        setPaginationOn(true)
      }

      setNumberOfPages(Math.ceil(response.data.totalUsers / limit))
      setUsersCompany(datas)
    } catch (err: any) {
      console.log('error', err)
    }

    setLoading(false)
  }

  useEffect(() => {
    getCompany()
  }, [page])

  const handleSubmit = async () => {
    try {
      if (fileToUpload) {
        setLoading(true)
        const formData = new FormData()
        formData.append('file', fileToUpload)
        formData.append('company_id', companyUuid)

        if (typeUser === 'titular') {
          formData.append('plan_id', selectedPlan)
        }
        let url = ''
        if (typeUser === 'titular') {
          url = 'manager/b2b/import?internal=true'
        } else if (typeUser === 'dependente') {
          url = 'manager/b2b/import-dependents?internal=true'
        } else if (typeUser === 'remove') {
          url = 'manager/b2b/remove'
        }

        const { data } = await api.post(url, formData)
        if (data?.item?.code === 'NotFound') {
          alertWarning(data?.item?.message || 'CSV vazio')
        }
        if (data?.item?.repeated?.length > 0) {
          setRepeatedUsers(data?.item?.repeated)
        } else {
          acceptedFiles.shift()
          setShowModal(false)
          getCompany()
          alertSuccess(data?.item?.message || 'Sucesso!')
        }
        setLoading(false)
      } else {
        alertWarning('É necessário informar um arquivo CSV.')
      }
    } catch (err: any) {
      alertWarning(err?.response?.data?.message)
    } finally {
      setLoading(false)
    }
  }
  const handleRemove = async () => {
    setLoading(true)
    try {
      const response = await api.delete(`/manager/client/b2b/${userRemove}`)
      alertSuccess(response?.data?.message || 'Usuário removido com sucesso')
      setLoading(false)

      getCompany()
    } catch (err: any) {
      alertWarning(err?.response?.data?.message || 'Erro ao remover usuário')
      setLoading(false)
    }
    setModalRemove(false)
  }

  const ContentShowRepeatedUsers = () => (
    <div className="d-flex flex-column h-100 justify-content-space-between">
      <div className="text-center" style={{ height: '20%' }}>
        <h1 className="text-uppercase">Usuários não cadastrados</h1>
        <p className="font-weight-bold text-muted">
          Houve algum problema para cadastrar os seguintes usuários em nossa
          base de dados, não sendo possível adicioná-los novamente:
        </p>
      </div>
      <div
        className="d-flex flex-column h-100 justify-content-center"
        style={{ flex: 1, height: '68%', overflowY: 'auto' }}
      >
        <ul>
          {repeatedUsers.map((item: any) => (
            <div>
              <li className="font-italic">
                {`${item.user} ---- ${item.message}`}
              </li>
            </div>
          ))}
        </ul>
      </div>
      <div
        className="d-flex align-items-end justify-content-end"
        style={{ height: '12%' }}
      >
        <div>
          <Button
            disabled={!fileToUpload}
            onClick={() => window.location.reload()}
          >
            Continuar
          </Button>
        </div>
      </div>
    </div>
  )
  const ContentCpfsInvalid = () => (
    <div className="d-flex flex-column h-100 justify-content-space-between">
      <div className="text-center" style={{ height: '20%' }}>
        <h1 className="text-uppercase">Dados inválidos</h1>
        <p className="font-weight-bold text-muted">
          Os seguintes usuários possuem CPFs ou data de nascimento inválidos:
        </p>
      </div>
      <div
        className="d-flex flex-column h-100 justify-content-center"
        style={{ flex: 1, height: '68%', overflowY: 'auto' }}
      >
        <ul>
          {usersinvalid.map((item) => (
            <li key={item} className="font-italic">
              {item}
            </li>
          ))}
        </ul>
      </div>
      <div
        className="d-flex align-items-end justify-content-end"
        style={{ height: '12%' }}
      >
        <div>
          <Button
            disabled={!fileToUpload}
            onClick={() => window.location.reload()}
          >
            OK
          </Button>
        </div>
      </div>
    </div>
  )

  const contentModalRemove = () => (
    <Modal
      isOpen={modalRemove}
      toggle={() => {
        setModalRemove(modalRemove)
      }}
    >
      <ModalHeader>
        <h3>Você deseja realmente remover esse usuário dessa empresa?</h3>
      </ModalHeader>
      <ModalBody className="p-4 d-flex justify-content-between">
        <Button
          color="primary"
          onClick={() => {
            handleRemove()
          }}
        >
          Sim
        </Button>
        <Button
          style={{ minWidth: 200 }}
          color="secondary"
          onClick={() => {
            setModalRemove(false)
          }}
        >
          Não
        </Button>
      </ModalBody>
    </Modal>
  )

  if (loading) return <Loading title="Aguarde..." />
  return (
    <div>
      <HeaderNeutro />
      <Container className="mt-3" fluid>
        <Row className="mt-3 align-items-end d-flex justify-content-between">
          <Col sm="auto" className="align-items-start d-flex ">
            <Button
              color="primary"
              onClick={() => {
                navigation.goBack()
              }}
            >
              <i className="fas fa-arrow-left" />
            </Button>
            <Button
              style={{ minWidth: 200 }}
              color="secondary"
              onClick={() => {
                setTypeUser('titular')
                setShowModal(true)
              }}
            >
              Importar Titulares
            </Button>
            <Button
              sm="auto"
              style={{ minWidth: 220 }}
              color="secondary"
              onClick={() => {
                setTypeUser('dependente')
                setShowModal(true)
              }}
            >
              Importar Dependentes
            </Button>
            <Button
              sm="auto"
              style={{ minWidth: 220 }}
              color="primary"
              outline
              onClick={() => {
                setTypeUser('remove')
                setShowModal(true)
              }}
            >
              Remover Titulares
            </Button>
          </Col>
          <Col sm="auto" className="d-flex justify-content-end">
            <Col xs="8">
              <Input
                type="text"
                id="filtrar-cpf"
                maxLength={14}
                value={name}
                placeholder="Filtrar pelo nome..."
                onChange={(e) => setName(e.target.value)}
              />
            </Col>
            <Col xs="auto">
              <Button
                color="secondary"
                onClick={() => {
                  getCompany(name, 1)
                }}
              >
                <i className="fas fa-search" />
              </Button>
            </Col>
            <Col xs="auto">
              <Button
                color="primary"
                onClick={() => {
                  setName('')
                  getCompany('', 1)
                }}
              >
                <i className="far fa-trash-alt" />
              </Button>
            </Col>
          </Col>
        </Row>
      </Container>

      <Container className="mt-3" fluid>
        <Card className="shadow">
          {loading ? (
            <Loading title="Carregando colaboradores..." />
          ) : (
            <>
              <CardHeader className="bg-transparent border-0">
                <Row className="align-items-center d-flex justify-content-between">
                  <Col sm="9" className="mb-2">
                    <h3 className="mb-0 text-black">{`Colaboradores | ${nameCompany}`}</h3>
                  </Col>
                  <Col sm="auto" className="mb-2">
                    <NewUserB2b
                      modal={modal}
                      setModal={setModal}
                      setLoading={setLoading}
                      company={{ uuid: companyUuid, plans }}
                      action={getCompany}
                    />
                  </Col>
                </Row>
              </CardHeader>

              <Row className="mt-0">
                <div className="col">
                  <Card className="shadow">
                    <Table
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">id</th>
                          <th scope="col">nome</th>
                          <th scope="col">email</th>
                          <th scope="col">cpf</th>
                          <th scope="col">status</th>
                          <th scope="col">Ação</th>
                        </tr>
                      </thead>

                      <tbody>
                        {usesCompany?.map((user: any) => (
                          <tr key={user.id}>
                            <th scope="row">{user.id}</th>
                            <td>{user.name}</td>
                            <td>{user.email}</td>
                            <td>{cpfCnpjMask(user.document)}</td>
                            <td>{user.status}</td>
                            <td>
                              {user.is_holder && (
                                <Button
                                  size="sm"
                                  color="primary"
                                  onClick={() => {
                                    setUserRemove(user.id)
                                    setModalRemove(true)
                                  }}
                                  style={{ width: 40 }}
                                >
                                  <i className="far fa-trash-alt" />
                                </Button>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    {paginationOn && (
                      <PaginationComponent
                        numberOfPages={numberOfPages}
                        page={page}
                        setPage={setPage}
                      />
                    )}
                  </Card>
                </div>
              </Row>
            </>
          )}
        </Card>
      </Container>
      {contentModalRemove()}
      {showModal && (
        <ModalContent>
          {usersinvalid.length > 0 && <ContentCpfsInvalid />}
          {repeatedUsers.length > 0 && <ContentShowRepeatedUsers />}
          {usersinvalid.length === 0 && repeatedUsers.length === 0 && (
            <div className="d-flex flex-column h-100 justify-content-space-between">
              <div className="text-center">
                <h1 className="text-uppercase">
                  {typeUser === 'remove'
                    ? 'Remover usuários'
                    : `Importador de Usuário`}
                </h1>
                <p className="font-weight-bold text-muted">
                  {`Selecione ou arraste o arquivo .CSV para ${
                    typeUser === 'remove'
                      ? 'remover os usuários!'
                      : `importar os novos
                    usuários!`
                  } `}
                </p>
                {usersinvalid.length > 0 && <ContentCpfsInvalid />}
                {typeUser === 'titular' && (
                  <FormGroup>
                    <Row className="justify-content-md-center align-items-md-center">
                      <Col lg="3">
                        <label htmlFor="selectPlan">Selecione o plano</label>
                      </Col>

                      <Col lg="5">
                        <Input
                          type="select"
                          id="selectPlan"
                          name="selectPlan"
                          value={selectedPlan}
                          onChange={(e) => setSelectedPlan(e.target.value)}
                        >
                          <option value="0" selected>
                            Selecione...
                          </option>
                          {plans.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.title}
                            </option>
                          ))}
                        </Input>
                      </Col>
                    </Row>
                  </FormGroup>
                )}
              </div>
              <div
                className="d-flex flex-column h-100 justify-content-center"
                style={{ flex: 1 }}
              >
                <ReactDropZoneInput getRootProps={getRootProps()}>
                  <input {...getInputProps()} />

                  {isDragActive ? (
                    <p
                      className="font-weight-bold text-muted"
                      style={{ fontSize: 18 }}
                    >
                      Solte o arquivo aqui...
                    </p>
                  ) : acceptedFiles.length > 0 ? (
                    <p
                      className="font-weight-bold text-muted"
                      style={{ fontSize: 18 }}
                    >
                      {acceptedFiles[0].name}
                    </p>
                  ) : (
                    <p
                      className="font-weight-bold text-muted"
                      style={{ fontSize: 18 }}
                    >
                      Arraste e solte o arquivo aqui, ou clique para
                      selecioná-lo
                    </p>
                  )}
                </ReactDropZoneInput>
              </div>
              <div className="d-flex align-items-center justify-content-end">
                <div>
                  <Button
                    disabled={
                      !fileToUpload ||
                      (selectedPlan === '0' && typeUser === 'titular') ||
                      (!fileToUpload && typeUser === 'dependente')
                    }
                    onClick={() => handleSubmit()}
                  >
                    {typeUser === 'remove' ? 'Remover' : 'Importar'}
                  </Button>
                </div>
                <div className="ml-3">
                  <Button
                    color="secondary"
                    outline
                    onClick={() => {
                      setShowModal(false)
                    }}
                  >
                    Cancelar
                  </Button>
                </div>
              </div>
            </div>
          )}
        </ModalContent>
      )}
    </div>
  )
}

export default UsersCompany
