import api from 'configs/api'
import { dateMaskUS } from 'js-essentials-functions'
import { SpecialtyProps } from 'context/doctors/doctors.types'
import moment from 'moment'

export const getAllDoctors = async (
  page: number,
  limit: number,
  document: string,
  specialty: string
) => {
  try {
    const { data } = await api.get(
      `/manager/doctors?page=${page}&limit=${limit}&filter=${document}&specialty_id=${specialty}`
    )

    const datas = data.items.map((item: any) => ({
      id: item.id,
      name: item.name,
      document: item.document,
      email: item.email,
      status: item.status,
    }))
    return {
      status: true,
      doctors: datas,
      total: data.total,
    }
  } catch (err: any) {
    return {
      status: false,
      message: err.response.data.message,
    }
  }
}

export interface DoctorData {
  name: string
  email: string
  password: string
  document: string
  cellphone: string
  gender: string
  birthDay: string
  professionalDocumentType: string
  professionalDocumentUf: string
  professionalDocumentNumber: string
  specialties: {
    id: string
    status: number
  }[]
  bio: string
  countries: string[]
  tags: string[]
}

export const createDoctor = async (data: DoctorData) => {
  try {
    const response = await api.post('/manager/create-doctor', {
      email: data.email,
      name: data.name,
      password: data.password,
      document: data.document,
      cellphone: data.cellphone,
      gender: data.gender,
      birth_day: data.birthDay,
      professional_document_type: data.professionalDocumentType,
      professional_document_uf: data.professionalDocumentUf,
      professional_document_number: data.professionalDocumentNumber,
      specialties: data.specialties,
      bio: data.bio,
      countries: data.countries,
      tags: data.tags,
    })

    return {
      status: true,
      data: response.data,
    }
  } catch (err: any) {
    return {
      status: false,
      msg: err.response.data.message,
    }
  }
}

export const createSpecialty = async ({
  name,
  status,
  attendanceTime,
}: SpecialtyProps) => {
  try {
    await api.post('doctors/v1/specialties', {
      name,
      status,
      attendance_time: Number(attendanceTime),
    })
    return {
      status: true,
    }
  } catch (err: any) {
    return {
      status: true,
      msg: err.message,
    }
  }
}
export const updateSpecialty = async ({
  uuid,
  name,
  status,
  attendanceTime,
}: SpecialtyProps) => {
  try {
    await api.put(`manager/specialty/${uuid}`, {
      name,
      status,
      attendance_time: Number(attendanceTime),
    })
    return {
      status: true,
    }
  } catch (err: any) {
    return {
      status: true,
      msg: err.message,
    }
  }
}

export const getSchedule = async (uuid: string) => {
  try {
    const response = await api.get(`doctors/v2/schedule?doctor_id=${uuid}`, {
      headers: {
        'Accept-Language': 'pt-BR',
      },
    })

    return {
      status: true,
      schedule: response.data.schedules,
    }
  } catch (err: any) {
    return {
      status: false,
      msg: err.message,
    }
  }
}

export const deleteSchedule = async (uuid: string) => {
  try {
    await api.delete(`/doctors/v1/schedule/slot/${uuid}`)

    return {
      status: true,
      msg: 'Agenda excluida com sucesso',
    }
  } catch (err: any) {
    return {
      status: false,
      msg: err.response.data.message,
    }
  }
}
interface ScheduleProps {
  userUuid: string
  areaId: string
  start: string
  end: string
  plan: string
}

export const createSchedule = async (body: ScheduleProps) => {
  try {
    const day = moment(body.start).format('YYYY-MM-DD')
    const startTime = moment(body.start).format('HH:mm:ss')
    const endTime = moment(body.end).format('HH:mm:ss')
    const groups = body.plan ? { groups: [body.plan] } : {}

    const { data } = await api.post(`doctors/v1/schedule`, {
      specialty_id: body.areaId,
      day,
      start_time: startTime,
      doctor_id: body.userUuid,
      end_time: endTime,
      recurrence: false,
      ...groups,
    })

    return {
      status: true,
      msg: 'Agenda criada com sucesso',
      schedule: data?.schedule,
    }
  } catch (err: any) {
    return {
      status: false,
      msg: err.response.data.message,
    }
  }
}

export const getDoctorData = async (uuid: string) => {
  try {
    const response = await api.get(`/manager/doctors/${uuid}`)
    const { item } = response?.data

    return {
      status: true,
      item,
    }
  } catch (err: any) {
    return {
      status: true,
      msg: err.message,
    }
  }
}

export const getDoctorSchedulings = async (uuid: string, days: any) => {
  try {
    const response = await api.get(
      `/schedulings/v2/doctor/${uuid}?status=all&start=${days.start}&end=${days.end}`
    )
    return {
      status: true,
      item: response?.data,
    }
  } catch (err: any) {
    return {
      status: true,
      msg: err.message,
    }
  }
}

export interface userUpdatePrps {
  id: string
  userData: {
    name: string
    email: string
    cellphone: string
    birthdate: string
    gender: string | null
    countries: string[]
    groups: string[]
    tags: string[]
    specialties: string[]
  }
}
export const updateDoctor = async ({ id, userData }: userUpdatePrps) => {
  try {
    const response = await api.put(`/manager/doctors/${id}`, {
      name: userData?.name,
      email: userData?.email,
      cellphone: userData?.cellphone,
      birthdate: dateMaskUS(userData?.birthdate),
      gender: userData?.gender,
      countries: userData?.countries,
      groups: userData?.groups,
      tags: userData?.tags,
      specialties: userData?.specialties,
    })

    return {
      status: true,
      message: response.data.message || 'Médico atualizado com sucesso.',
    }
  } catch (err: any) {
    return {
      status: false,

      message: err.response.data.message,
    }
  }
}
interface UpdateBioParams {
  id: string
  bio: string
  lang: string
}

export const updateBio = async ({ id, bio, lang }: UpdateBioParams) => {
  try {
    const response = await api.put(`manager/doctors/${id}/bio`, {
      lang,
      bio,
    })
    return {
      status: true,
      message: response.data.message || 'Bio do médico atualizado com sucesso.',
    }
  } catch (err: any) {
    const message =
      err.response?.data?.message || 'Erro ao atualizar a bio do médico.'
    return {
      status: false,
      message,
    }
  }
}

export const updateDoctorBio = async ({ id, bio, lang }: any) => {
  try {
    const response = await api.put(`manager/doctors/${id}/bio`, {
      lang,
      bio,
    })

    return {
      status: true,
      message: response.data.message || 'Bio do médico atualizado com sucesso.',
    }
  } catch (err: any) {
    return {
      status: false,

      message: err.response.data.message,
    }
  }
}

export const changeScheduling = async (uuid: string, data: any) => {
  try {
    await api.put(`/schedulings/ms/v1/${uuid}/change-date`, data)

    return {
      status: true,
      msg: 'Agendamento alterado com sucesso',
    }
  } catch (err: any) {
    return {
      status: false,
      msg: err.message,
    }
  }
}

export const deleteScheduling = async (uuid: string) => {
  try {
    await api.post(`/manager/doctors/scheduling/${uuid}`, {
      cancel_description: 'Cancelado pelo suporte',
    })

    return {
      status: true,
      msg: 'Agendamento excluido com sucesso',
    }
  } catch (err: any) {
    return {
      status: false,
      msg: err.message,
    }
  }
}

export const getDaysAVailable = async (
  uuid: string,
  date: string,
  areaId: string,
  time: string
) => {
  try {
    const response = await api.get(
      `/schedulings/v3/verify-time?specialty_id=${areaId}&doctor_id=${uuid}&scheduling_date=${date}&time=${time}`
    )
    return {
      status: true,
      data: response.data.times,
    }
  } catch (err: any) {
    console.log('err', err)
    return {
      status: false,
      msg: err.message,
    }
  }
}
