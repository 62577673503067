import React, { useState } from 'react'
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  CardBody,
  FormGroup,
  Input,
  Label,
  CardImg,
  Button,
} from 'reactstrap'
import ComponentBack from 'components/ComponentBack'
import HeaderNeutro from 'components/Headers/HeaderNeutro'
import placeholdeNot from 'assets/img/placeholder/notification.png'
import api from 'configs/api'
import { alertDanger, alertSuccess } from 'utils/toast'
import moment from 'moment'
import Loading from 'components/Loading'

const today = new Date()
const defaultNotification = {
  name: '',
  body: '',
  title: '',
}
const defaultChecks = {
  user: false,
  assignment: false,
  date: false,
}

const CreateNotification = () => {
  const [notification, setNotification] = useState(defaultNotification)
  const [date, setDate] = useState(moment(today).format('YYYY-MM-DD'))
  const [checks, setChecks] = useState(defaultChecks)
  const [typeUser, setTypeUser] = useState('b2c')
  const [seguiment, setSeguiment] = useState('')
  const [time, setTime] = useState(
    moment(today).locale('pt-br').format('HH:mm')
  )
  const [loading, setLoading] = useState(false)

  const disabledButon = Object.values(notification).includes('')
  const isStar = checks.user || checks.assignment
  const dateFormated = moment(`${date} ${time}`).format('YYYY-MM-DD HH:mm:ss')

  const handleSubmit = async () => {
    setLoading(true)
    if (isStar) {
      try {
        const datas = {
          name: notification.name,
          description: notification.body,
          title: notification.title,
        }
        if (checks.date) {
          Object.assign(datas, {
            send_after: `${dateFormated} GMT-0300"`,
          })
        }
        const queryStart = isStar ? `&is_star=${!checks.user}` : ''

        await api.post(
          `/manager/push/users?page=1&limit=2000&user_type=${typeUser}${queryStart}`,
          datas
        )
        setNotification(defaultNotification)
        setChecks(defaultChecks)
        alertSuccess('Notificação enviada com sucesso!')
      } catch (err: any) {
        alertDanger(err.response.data.message || 'Erro ao enviar notificação')
      }
    } else {
      try {
        const datas = {
          name: notification.name,
          headings: {
            en: notification.title,
          },
          contents: {
            en: notification.body,
          },
          included_segments: [seguiment],
        }

        await api.post(`/notification/v1/push`, datas)
        setNotification(defaultNotification)
        setChecks(defaultChecks)
        alertSuccess('Notificação enviada com sucesso!')
      } catch (err: any) {
        alertDanger(err.response.data.message || 'Erro ao enviar notificação')
      }
    }
    setLoading(false)
  }

  const onCheck = (e: any) => {
    const { name, checked } = e.target
    setChecks({ ...checks, [name]: checked })
  }
  if (loading) return <Loading title="Aguarde...." />

  return (
    <div>
      <HeaderNeutro />
      <ComponentBack />
      <Container className="mt-3" fluid>
        <Card className="shadow">
          <CardHeader className="bg-transparent border-0">
            <Row className="align-items-center">
              <Col sm="9" className="mb-2">
                <h3 className="mb-0 text-black">Enviar Notificação Push</h3>
              </Col>
            </Row>
          </CardHeader>
          <CardBody
            style={{ backgroundColor: '#F7FAFC' }}
            className="pb-0 pr-0"
          >
            <Row>
              <Col ls="7">
                <Row ls="12">
                  <Col sm="12">
                    <h5 className="mb-0 text-gray">Configurações do Push</h5>
                  </Col>
                  <Col sm="12">
                    <div className="col" style={{ marginTop: 20 }}>
                      <FormGroup check>
                        <Input
                          name="user"
                          type="checkbox"
                          id="usuários"
                          onChange={(e) => onCheck(e)}
                          disabled={checks.assignment}
                        />
                        <Label
                          check={checks.user}
                          size="sm"
                          className=" text-gray"
                          for="usuários"
                        >
                          Enviar para usuários cadastrados no Aplicativo que não
                          são assinantes
                        </Label>
                      </FormGroup>
                      <FormGroup check>
                        <Input
                          name="assignment"
                          type="checkbox"
                          id="assinantes"
                          disabled={checks.user}
                          onChange={(e) => onCheck(e)}
                        />
                        <Label
                          check={checks.assignment}
                          size="sm"
                          className=" text-gray"
                          for="assinantes"
                        >
                          Enviar para assinantes
                        </Label>
                      </FormGroup>
                      <FormGroup check>
                        <Input
                          name="date"
                          type="checkbox"
                          id="agendar"
                          onChange={(e) => onCheck(e)}
                        />
                        <Label
                          check={checks.date}
                          size="sm"
                          className=" text-gray"
                          for="agendar"
                        >
                          Agendar data e hora do envio
                        </Label>
                      </FormGroup>
                    </div>
                  </Col>
                </Row>
                <Row className="col d-flex mt-4" sm="12">
                  <Col sm="5">
                    <FormGroup>
                      <Label>Tipo do usuário</Label>
                      <Input
                        type="select"
                        disabled={!checks.user && !checks.assignment}
                        onChange={(e) => setTypeUser(e.target.value)}
                      >
                        <option value="b2c"> B2C - Cliente normal</option>
                        <option value="b2b"> B2B - Cliente normal</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col sm="4">
                    <FormGroup>
                      <Label for="exampleDate">Data do envio</Label>
                      <Input
                        id="exampleDate"
                        name="date"
                        placeholder="date placeholder"
                        type="date"
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                        disabled={!checks.date}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="3">
                    <FormGroup>
                      <Label for="exampleTime">Horário</Label>
                      <Input
                        id="exampleTime"
                        name="time"
                        placeholder="time placeholder"
                        type="time"
                        value={time}
                        onChange={(e) => setTime(e.target.value)}
                        disabled={!checks.date}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="5">
                    <FormGroup>
                      <Label>Seguimento</Label>
                      <Input
                        type="select"
                        style={{ paddingRight: 10 }}
                        disabled={checks.assignment || checks.user}
                        onChange={(e) => setSeguiment(e.target.value)}
                      >
                        <option value="Subscribed Users">
                          Todos os usuários inscritos
                        </option>
                        <option value="Active Users">Usuários Ativos</option>
                        <option value="Engaged Users">
                          Usuários Engajados
                        </option>
                        <option value="Inactive Users">
                          Usuários Inativos
                        </option>
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col sm="12">
                    <h5 className="mb-0 text-gray">Informações do Push</h5>
                  </Col>
                  <Col sm="12">
                    <div className="d-flex" style={{ marginTop: 20 }}>
                      <Col sm="4">
                        <FormGroup>
                          <Label size="sm" className=" text-gray">
                            Nome
                          </Label>
                          <Input
                            value={notification.name}
                            onChange={(e) =>
                              setNotification({
                                ...notification,
                                name: e.target.value,
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="8">
                        <FormGroup>
                          <Label size="sm" className=" text-gray">
                            Titulo
                          </Label>
                          <Input
                            value={notification.title}
                            onChange={(e) =>
                              setNotification({
                                ...notification,
                                title: e.target.value,
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                    </div>
                  </Col>
                  <div className="col d-flex">
                    <Col sm="12">
                      <FormGroup>
                        <Label size="sm" className=" text-gray">
                          Corpo da mensagem
                        </Label>
                        <Input
                          value={notification.body}
                          onChange={(e) =>
                            setNotification({
                              ...notification,
                              body: e.target.value,
                            })
                          }
                          type="textarea"
                        />
                      </FormGroup>
                    </Col>
                  </div>
                </Row>
                <Row className="mb-3">
                  <div className="col d-flex justify-content-end">
                    <Col sm="auto">
                      <Button
                        onClick={() => handleSubmit()}
                        disabled={disabledButon}
                        color="secondary"
                      >
                        Enviar Push
                      </Button>
                    </Col>
                  </div>
                </Row>
              </Col>
              <Col sm="5 " className="d-flex  align-items-end">
                <CardImg alt="Notificação" src={placeholdeNot} />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}
export default CreateNotification
