/* eslint-disable radix */
export function validarCPF(cpf: string) {
  cpf = cpf?.replace(/\D/g, '')

  if (cpf?.length !== 11) {
    return false
  }

  if (/^(\d)\1{10}$/.test(cpf)) {
    return false
  }

  let soma = 0
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < 9; i++) {
    soma += parseInt(cpf.charAt(i)) * (10 - i)
  }
  const digitoVerificador1 = soma % 11 < 2 ? 0 : 11 - (soma % 11)

  if (parseInt(cpf.charAt(9)) !== digitoVerificador1) {
    return false
  }

  soma = 0
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < 10; i++) {
    soma += parseInt(cpf.charAt(i)) * (11 - i)
  }
  const digitoVerificador2 = soma % 11 < 2 ? 0 : 11 - (soma % 11)

  if (parseInt(cpf.charAt(10)) !== digitoVerificador2) {
    return false
  }

  return true
}
