import React, { useState, useCallback, useMemo, useEffect } from 'react'
import {
  cpfCnpjMask,
  cepMask,
  cpfCnpjUnmask,
  telephoneMask,
  telephoneUnmask,
  cepUnmask,
} from 'js-essentials-functions'
import { FormikProvider, useFormik, Field } from 'formik'
import * as yup from 'yup'
import {
  Button,
  CardHeader,
  Col,
  Row,
  FormGroup,
  Input,
  Label,
  Form,
  CardBody,
  Media,
  Modal,
  ModalHeader,
  ModalBody,
  Card,
} from 'reactstrap'
import api from 'configs/api'
import Loading from 'components/Loading'
import PermissionComponent from 'components/PermissionComponent'
import { useLocation } from 'react-router-dom'
import { alertSuccess } from 'utils/toast'
import { useDropzone } from 'react-dropzone'
import useSpecialty from 'hooks/useSpecialty'

const schemaRegister = yup.object().shape({
  legalName: yup.string().required('Informe o nome Jurídico!'),
  legalNature: yup.string().required('Informe o nome Fantasia!'),
  cnpj: yup.string().required('Informe o cnpj!'),
  email: yup.string().required('Informe o email!'),
  cellphone: yup.string().required('Informe o telefone!'),
  street: yup.string().required('Informe a rua!'),
  number: yup.string().required('Informe o número!'),
  // complement: yup.string(),
  neighborhood: yup.string().required('Informe o bairro!'),
  city: yup.string().required('Informe a cidade!'),
  state: yup.string().required('Informe o estado!'),
  zip: yup.string().required('Informe o cep!'),
  plan: yup.string().required('Informe o plano!'),
})

const SettingsCompany = ({
  company,
  changeStatus,
  isActive,
  specialties,

  accessFinancial,
  setNotAccess,
  changeBlock,
}: any) => {
  const [specialtiesAll, setSpecialtiesAll] = useState<any>()

  const [logoCompany, setLogoCompany] = useState('')
  const [imageLogo, setImageLogo] = useState<any>()
  const [modalOpen, setModalOpen] = useState(false)
  const [modalBlock, setModalBlock] = useState(false)

  const [editable, setEditable] = useState(false)
  const [loading, setLoading] = useState(false)

  const [plans, setPlans] = useState<Array<any>>([])
  const [selectedPlan, setSelectedPlan] = useState('')
  const { filterSpecialtiesActive } = useSpecialty()

  const params: any = useLocation()

  const companyId =
    params.pathname.split('/')[params.pathname.split('/').length - 1]

  const initialValues = useMemo(
    () => ({
      legalName: '',
      legalNature: '',
      cnpj: '',
      logo: '',
      email: '',
      cellphone: '',
      street: '',
      number: '',
      // complement: '',
      neighborhood: '',
      city: '',
      state: '',
      zip: '',
      plan: '',
    }),
    []
  )
  const checkedSpecialty = () =>
    specialtiesAll
      .map((specialty: any) => {
        if (specialty.checked) {
          return String(specialty.id)
        }
        return null
      })
      .filter((area: any) => !!area)
      .join()

  const onDrop = useCallback((acceptedFiles) => {
    setImageLogo(acceptedFiles)
    setLogoCompany(URL.createObjectURL(acceptedFiles[0]))
  }, [])
  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  const formik = useFormik({
    initialValues,
    validationSchema: schemaRegister,
    onSubmit: async (values) => {
      // if (!checkedSpecialty()) {
      //   return alertWarning('Especialidades são obrigatórias')
      // }
      const data = new FormData()
      if (imageLogo) {
        imageLogo?.map((file: any) => data.append('files', file))
      }
      data.append('national_registration', cpfCnpjUnmask(values.cnpj))
      data.append('legal_name', values.legalName)
      data.append('legal_nature', values.legalNature)
      data.append('email', values.email)
      data.append('cellphone', telephoneUnmask(values.cellphone))
      data.append('street', values.street)
      data.append('number', values.number)
      data.append('neighborhood', values.neighborhood)
      data.append('city', values.city)
      data.append('state', values.state)
      data.append('zip_code', cepUnmask(values.zip))
      data.append('plan_id', values.plan)
      data.append('area_id', checkedSpecialty())
      setLoading(true)

      try {
        await api.put(`/manager/companies/${companyId}`, data)
        alertSuccess('Dados atualizados com sucesso!')
        if (
          !values?.cellphone ||
          !values?.email ||
          !values?.zip ||
          !values?.state ||
          !selectedPlan ||
          !values?.street ||
          !values?.city
        ) {
          setNotAccess(true)
        } else {
          setNotAccess(false)
        }
      } catch (e) {
        console.log(e)
      }
      setLoading(false)
      setEditable(false)
      return true
    },
  })

  const getAllSpecialties = useCallback(
    (arrayAreaId: String[]) => {
      setSpecialtiesAll(
        filterSpecialtiesActive().map((specialty: any) => ({
          ...specialty,
          checked: arrayAreaId.includes(String(specialty.id)),
        }))
      )
    },
    [specialties]
  )

  const onChangeCheckedSpecialty = (id: string) => {
    const newDatas = specialtiesAll.map((specialty: any) => {
      if (specialty.id === id) {
        if (specialty.checked) {
          return {
            ...specialty,
            checked: false,
          }
        }
        return {
          ...specialty,
          checked: true,
        }
      }
      return specialty
    })
    setSpecialtiesAll(newDatas)
  }

  useEffect(() => {
    formik.setValues({
      legalName: company?.legal_name,
      legalNature: company?.legal_nature,
      cnpj: company?.national_registration || '',
      logo: company?.logo || '',
      email: company?.email || '',
      cellphone: company?.cellphone || '',
      street: company?.street || '',
      number: company?.number || '',
      // complement: company?.complement || '',
      neighborhood: company?.neighborhood || '',
      city: company?.city || '',
      state: company?.state || '',
      zip: company?.zip_code || '',
      plan: company?.plan_id || '',
    })
    setLogoCompany(company?.logo)
    setSelectedPlan(company?.plan_id)
  }, [company])

  useEffect(() => {
    const getPlans = async () => {
      try {
        if (company) {
          const res = await api.get(`manager/plans?slug=star-empresa`)
          setPlans(res.data.items)
        }
      } catch (err) {
        console.log(err)
      }
    }

    getPlans()
  }, [company])

  useEffect(() => {
    getAllSpecialties(specialties)
  }, [specialties])

  const contentModalChange = () => (
    <Modal
      isOpen={modalOpen}
      toggle={() => {
        setModalOpen(modalOpen)
      }}
    >
      {isActive ? (
        <ModalHeader>
          <h3>Você deseja realmente desativar essa empresa?</h3>
          <h4>OBS:</h4>
          <h5>- Todos os Administradores serão desativados.</h5>
          <h5>- Todos os Colaboradores serão vinculados ao Star Free.</h5>
        </ModalHeader>
      ) : (
        <ModalHeader>
          <h3>Você deseja ativar essa empresa?</h3>
        </ModalHeader>
      )}
      <ModalBody className="p-4 d-flex justify-content-between">
        <Button
          color="primary"
          onClick={() => {
            changeStatus()
            setModalOpen(false)
          }}
        >
          Sim
        </Button>
        <Button
          style={{ minWidth: 200 }}
          color="secondary"
          onClick={() => {
            setModalOpen(false)
          }}
        >
          Não
        </Button>
      </ModalBody>
    </Modal>
  )

  const contentModalBlock = () => (
    <Modal
      isOpen={modalBlock}
      toggle={() => {
        setModalBlock(!modalBlock)
      }}
    >
      {company?.usersBlocked ? (
        <ModalHeader>
          <h3>Você deseja desbloquear os usuários dessa empresa?</h3>
        </ModalHeader>
      ) : (
        <ModalHeader>
          <h3>Você deseja realmente bloquear os usuários dessa empresa?</h3>
        </ModalHeader>
      )}
      <ModalBody className="p-4 d-flex justify-content-between">
        <Button
          color="primary"
          onClick={() => {
            changeBlock()
            setModalBlock(false)
          }}
        >
          Sim
        </Button>
        <Button
          style={{ minWidth: 200 }}
          color="secondary"
          onClick={() => {
            setModalBlock(false)
          }}
        >
          Não
        </Button>
      </ModalBody>
    </Modal>
  )

  return (
    <div>
      {contentModalChange()}
      {contentModalBlock()}
      {loading ? (
        <Loading title="Carregando..." />
      ) : (
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit} className="m-0 p-0">
            <CardHeader
              className="bg-transparent border-0"
              style={{ minHeight: 100 }}
            >
              <Row className="mt-3 align-items-end d-flex justify-content-between align-items-center">
                <Col sm="auto" className="mb-2">
                  <h3 className="mb-0 text-black">{company?.legal_nature}</h3>
                </Col>
                <Col sm="auto" className="mb-2">
                  {!editable && (
                    <Button
                      color="secondary"
                      style={{ width: 150 }}
                      disabled={!isActive}
                      onClick={() => {
                        if (
                          !company.cellphone ||
                          !company.email ||
                          !company.zip_code
                        ) {
                          formik.handleSubmit()
                        }
                        setEditable(true)
                      }}
                    >
                      Editar
                    </Button>
                  )}
                  {editable && (
                    <Button
                      color="primary"
                      type="submit"
                      disabled={!formik.isValid}
                    >
                      Salvar
                    </Button>
                  )}
                </Col>
              </Row>
            </CardHeader>
            <CardBody className="shadow">
              {!accessFinancial && (
                <Col lg="3">
                  <Card color="red" className="p-2">
                    <h4 style={{ color: 'white' }}>
                      Para acessar o finaceiro, preencha todos os dados da
                      empresa abaixo!
                    </h4>
                  </Card>
                </Col>
              )}
              <Row>
                <Col sm="12">
                  <div className="pl-lg-4 mt-4">
                    <Row>
                      <Col lg="3">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Nome Jurídico
                          </label>
                          <Input
                            className="form-control-alternative"
                            placeholder="Informe o Nome Jurídico.."
                            name="legalName"
                            type="text"
                            disabled={!editable}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.legalName}
                            error={formik.errors.legalName}
                          />
                          {formik.errors.legalName &&
                            formik.touched.legalName && (
                              <div className="input-feedback">
                                {formik.errors.legalName}
                              </div>
                            )}
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Nome Fantasia
                          </label>
                          <Input
                            className="form-control-alternative"
                            placeholder="Informe o nome.."
                            name="legalNature"
                            type="text"
                            disabled={!editable}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.legalNature}
                            error={formik.errors.legalNature}
                          />
                          {formik.errors.legalNature &&
                            formik.touched.legalNature && (
                              <div className="input-feedback">
                                {formik.errors.legalNature}
                              </div>
                            )}
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            CNPJ
                          </label>
                          <Input
                            className="form-control-alternative"
                            placeholder="Informe o CNPJ.."
                            name="cnpj"
                            type="text"
                            disabled
                            maxLength={18}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={cpfCnpjMask(formik.values.cnpj || '')}
                            error={formik.errors.cnpj}
                          />
                          {formik.errors.cnpj && formik.touched.cnpj && (
                            <div className="input-feedback">
                              {formik.errors.cnpj}
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col
                        sm="3"
                        className="d-flex flex-column align-items-center "
                      >
                        <Media
                          style={{
                            cursor: 'pointer',
                          }}
                          className="d-flex flex-column align-items-center "
                          {...getRootProps()}
                        >
                          <label className="form-control-label" htmlFor="logo">
                            Logo
                          </label>
                          <input {...getInputProps()} disabled={!editable} />

                          <Media
                            style={{
                              width: '150px',
                              height: '100px',
                              borderRadius: '8px',
                              border: '3px dashed #dee2e6',
                            }}
                            className=" align-items-center justify-content-center position-relative"
                          >
                            <>
                              {logoCompany ? (
                                <img
                                  src={logoCompany}
                                  alt="Logo"
                                  style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'contain',
                                  }}
                                />
                              ) : (
                                <h5 className=" text-gray">Adicionar</h5>
                              )}
                            </>
                          </Media>
                        </Media>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="3">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="email">
                            Email
                          </label>
                          <Input
                            className="form-control-alternative"
                            placeholder="Informe o Email.."
                            name="email"
                            type="text"
                            disabled={!editable}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.email || ''}
                            error={formik.errors.email}
                          />
                          {formik.errors.email && formik.touched.email && (
                            <div className="input-feedback">
                              {formik.errors.email}
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="cellphone"
                          >
                            Telefone
                          </label>
                          <Input
                            className="form-control-alternative"
                            placeholder="Informe o Telefone.."
                            name="cellphone"
                            type="text"
                            maxLength={15}
                            disabled={!editable}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={telephoneMask(formik.values.cellphone || '')}
                            error={formik.errors.cellphone}
                          />
                          {formik.errors.cellphone &&
                            formik.touched.cellphone && (
                              <div className="input-feedback">
                                {formik.errors.cellphone}
                              </div>
                            )}
                        </FormGroup>
                      </Col>
                      <Col lg="2">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="iselectPlan"
                          >
                            Selecione o plano
                          </label>
                          <Input
                            type="select"
                            id="plan"
                            name="plan"
                            disabled={!editable}
                            value={formik.values.plan}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            error={formik.errors.plan}
                          >
                            <option value="">Selecione...</option>
                            {plans.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.title}
                              </option>
                            ))}
                          </Input>
                          {formik.errors.plan && formik.touched.plan && (
                            <div className="input-feedback">
                              {formik.errors.plan}
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <PermissionComponent role="SUPERADMIN,ADMIN">
                        <Col lg="2">
                          <FormGroup
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                            }}
                          >
                            <label
                              className="form-control-label"
                              htmlFor="input-status"
                            >
                              Status
                            </label>
                            <label className="custom-toggle">
                              <input
                                checked={isActive}
                                type="checkbox"
                                onChange={() => {
                                  setModalOpen(true)
                                }}
                              />
                              <span className="custom-toggle-slider rounded-circle" />
                            </label>
                          </FormGroup>
                        </Col>
                      </PermissionComponent>
                    </Row>
                    <Row>
                      <PermissionComponent role="SUPERADMIN,ADMIN">
                        <Col lg="2">
                          <FormGroup
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                            }}
                          >
                            <label
                              className="form-control-label"
                              htmlFor="input-status"
                            >
                              {company?.usersBlocked
                                ? 'Desbloquear usuários'
                                : 'Bloquear usuários'}
                            </label>
                            <label className="custom-toggle">
                              <input
                                checked={company?.usersBlocked}
                                type="checkbox"
                                onChange={() => {
                                  setModalBlock(true)
                                }}
                              />
                              <span className="custom-toggle-slider rounded-circle" />
                            </label>
                          </FormGroup>
                        </Col>
                      </PermissionComponent>
                    </Row>
                    <hr style={{ marginTop: 0 }} />
                    <Row className="d-flex">
                      <Col md="6">
                        <FormGroup>
                          <Label for="street" style={{ fontSize: 14 }}>
                            Rua
                          </Label>
                          <Input
                            className="form-control-alternative"
                            name="street"
                            type="text"
                            disabled={!editable}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.street || ''}
                            error={formik.errors.street}
                          />
                          {formik.errors.street && formik.touched.street && (
                            <div className="input-feedback">
                              {formik.errors.street}
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md="2">
                        <FormGroup>
                          <Label for="number" style={{ fontSize: 14 }}>
                            Nº
                          </Label>
                          <Input
                            className="form-control-alternative"
                            name="number"
                            type="text"
                            disabled={!editable}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.number || ''}
                            error={formik.errors.number}
                          />
                          {formik.errors.number && formik.touched.number && (
                            <div className="input-feedback">
                              {formik.errors.number}
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      {/* <Col md="4">
                        <FormGroup>
                          <Label for="complement" style={{ fontSize: 14 }}>
                            Complemento
                          </Label>
                          <Input
                            className="form-control-alternative"
                            name="complement"
                            type="text"
                            disabled={!editable}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.complement || ''}
                            error={formik.errors.complement}
                          />
                        </FormGroup>
                      </Col> */}
                      <Col md="4">
                        <FormGroup>
                          <Label for="neighborhood" style={{ fontSize: 14 }}>
                            Bairro
                          </Label>
                          <Input
                            className="form-control-alternative"
                            name="neighborhood"
                            type="text"
                            disabled={!editable}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.neighborhood || ''}
                            error={formik.errors.neighborhood}
                          />
                          {formik.errors.neighborhood &&
                            formik.touched.neighborhood && (
                              <div className="input-feedback">
                                {formik.errors.neighborhood}
                              </div>
                            )}
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <Label for="city" style={{ fontSize: 14 }}>
                            Cidade
                          </Label>
                          <Input
                            className="form-control-alternative"
                            name="city"
                            type="text"
                            disabled={!editable}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.city}
                            error={formik.errors.city}
                          />
                          {formik.errors.city && formik.touched.city && (
                            <div className="input-feedback">
                              {formik.errors.city}
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <Label for="state" style={{ fontSize: 14 }}>
                            Estado
                          </Label>
                          <Input
                            className="form-control-alternative"
                            name="state"
                            type="text"
                            disabled={!editable}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.state || ''}
                            error={formik.errors.state}
                          />
                          {formik.errors.state && formik.touched.state && (
                            <div className="input-feedback">
                              {formik.errors.state}
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <Label for="zip" style={{ fontSize: 14 }}>
                            CEP
                          </Label>
                          <Input
                            className="form-control-alternative"
                            name="zip"
                            type="text"
                            maxLength={9}
                            disabled={!editable}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={cepMask(formik.values.zip) || ''}
                            error={formik.errors.zip}
                          />
                          {formik.errors.zip && formik.touched.zip && (
                            <div className="input-feedback">
                              {formik.errors.zip}
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <hr style={{ marginTop: 0 }} />
                    <Row className="mb-0">
                      <Col lg="auto" className="mb-0">
                        <h3 className="form-control-label">
                          Especialidades Disponíveis
                        </h3>
                        <div className="overflow-auto d-flex mb-0">
                          <FormGroup className="d-flex flex-wrap">
                            {specialtiesAll?.map((specialty: any) => (
                              <Label
                                className="mr-3 align-items-center justify-content-center"
                                size="sm"
                                key={specialty.id}
                              >
                                <Field
                                  name="specialties"
                                  type="checkbox"
                                  disabled={!editable}
                                  className="mr-2 align-items-center justify-content-center"
                                  value={specialty.name}
                                  onClick={() => {
                                    onChangeCheckedSpecialty(specialty.id)
                                  }}
                                  checked={specialty.checked}
                                />
                                {specialty.name}
                              </Label>
                            ))}
                          </FormGroup>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Form>
        </FormikProvider>
      )}
    </div>
  )
}

export default SettingsCompany
