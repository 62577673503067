import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'

import 'assets/plugins/nucleo/css/nucleo.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'assets/css/argon-dashboard-react.css'
import 'assets/scss/argon-dashboard-react.scss'
import 'assets/scss/global.scss'
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file

import AdminLayout from 'layouts/Admin'
import AuthLayout from 'layouts/Auth'
import { AuthProvider } from 'context/authentication/auth.context'
import { DoctorProvider } from 'context/doctors/doctors.context'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import moment from 'utils/moment'

moment.locale('pt-br')

ReactDOM.render(
  <AuthProvider>
    <DoctorProvider>
      <BrowserRouter>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Switch>
          <Route path="/auth" render={() => <AuthLayout />} />
          <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
          <Redirect from="/" to="/auth/login" />
        </Switch>
      </BrowserRouter>
    </DoctorProvider>
  </AuthProvider>,
  document.getElementById('root')
)
