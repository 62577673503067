import React, { useState } from 'react'
import classnames from 'classnames'
import {
  Card,
  CardBody,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap'
import HeaderNeutro from 'components/Headers/HeaderNeutro'
import History from './Tabs/History'
import BilletMonth from './Tabs/BilletMonth'
import Reports from './Tabs/Reports'

const Financial = () => {
  const [tabs, setTabs] = useState(1)

  return (
    <div>
      <HeaderNeutro />
      <Container className="mt-3" fluid>
        <div className="nav-wrapper">
          <Nav
            className="flex-column flex-md-row"
            id="tabs-icons-text"
            pills
            role="tablist"
          >
            <NavItem>
              <NavLink
                aria-selected={tabs === 1}
                className={classnames('mb-sm-3 mb-md-0', {
                  active: tabs === 1,
                })}
                onClick={() => setTabs(1)}
                href="#Configurações"
                role="tab"
              >
                <i className="ni ni-settings-gear-65 mr-2" />
                Boleto das empresas
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                aria-selected={tabs === 2}
                className={classnames('mb-sm-3 mb-md-0', {
                  active: tabs === 2,
                })}
                onClick={() => setTabs(2)}
                href="#Configurações"
                role="tab"
              >
                <i className="fas fa-users mr-2" />
                Histórico
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                aria-selected={tabs === 3}
                className={classnames('mb-sm-3 mb-md-0', {
                  active: tabs === 3,
                })}
                onClick={() => setTabs(3)}
                href="#Configurações"
                role="tab"
              >
                <i className="fas fa-file-alt mr-2" />
                Relatórios
              </NavLink>
            </NavItem>
          </Nav>
        </div>
        <Card className="shadow">
          <CardBody className="p-0">
            <TabContent activeTab={`tabs${tabs}`}>
              <TabPane tabId="tabs1">
                <BilletMonth />
              </TabPane>
              <TabPane tabId="tabs2">
                <History />
              </TabPane>
              <TabPane tabId="tabs3">
                <Reports />
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}

export default Financial
