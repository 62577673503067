import React, { useMemo, useState, useEffect } from 'react'
// import { useDropzone } from 'react-dropzone'
import api from 'configs/api'
// Components
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Button,
} from 'reactstrap'
import InputPassword from 'components/InputPassword'
import Loading from 'components/Loading'
import { FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'
import { alertSuccess, alertWarning } from 'utils/toast'

// import defaultImg from 'assets/img/brand/avatar.jpg'
import HeaderNeutro from 'components/Headers/HeaderNeutro'
import { useAuth } from 'context/authentication/auth.context'

// const regexPassword = /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])/
const schemaRegister = yup.object().shape({
  name: yup.string().required('Informe o nome!'),
  email: yup
    .string()
    .required('Informe o email!')
    .email('Informe um email valido!'),
  password: yup.string(),
})

const Profile = () => {
  const [profileEditable, setProfileEditable] = useState(false)
  const { adminData } = useAuth()
  const [loading, setLoading] = useState(false)
  const initialValues = useMemo(
    () => ({
      name: '',
      email: '',
      password: '',
    }),
    []
  )
  const formik = useFormik({
    initialValues,
    validationSchema: schemaRegister,
    onSubmit: async (values) => {
      const datas = {
        email: values.email,
        name: values.name,
        password: values.password,
      }
      setLoading(true)
      try {
        const response = await api.put('/manager/user', datas)
        alertSuccess(response.data.message)
      } catch (e: any) {
        alertWarning(
          e.response.data.message || 'Não foi possivel atualizar o perfil'
        )
      }
      setLoading(false)
    },
  })
  // const onDrop = useCallback((acceptedFiles) => {
  //   console.log(acceptedFiles)
  // }, [])
  // const { getRootProps, getInputProps } = useDropzone({ onDrop })
  useEffect(() => {
    formik.setValues({
      name: adminData.name,
      email: adminData.email,
      password: '',
    })
  }, [])
  if (loading) return <Loading title="Atualizando dados" />
  return (
    <>
      <HeaderNeutro />
      <Container className="mt-3" fluid>
        <Row className="mt-3 align-items-end d-flex ">
          <Col>
            <Card className="bg-white shadow">
              <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit} autoComplete="off">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center justify-content-between">
                      <Col xs="8">
                        <h3 className="mb-0">Informações do Usuário</h3>
                      </Col>
                      <Col sm="auto">
                        <Button
                          color="secondary"
                          onClick={() => {
                            setProfileEditable(!profileEditable)
                          }}
                          disabled={!formik.isValid}
                          type={!profileEditable ? 'submit' : 'button'}
                        >
                          {profileEditable ? 'Salvar' : 'Editar'}
                        </Button>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    {/* <Col sm="4">
                    <Media
                      style={{
                        cursor: 'pointer',
                        width: '200px',
                        height: '200px',
                      }}
                      className="d-flex flex-column align-items-center m-auto"
                      {...getRootProps()}
                    >
                      <Media className="mt-2">Foto</Media>
                      <input {...getInputProps()} />
                      <Media
                        style={{ width: '100px', height: '100px' }}
                        className="avatar  mt-4  rounded-circle align-items-center position-relative"
                      >
                        <img src={defaultImg} alt="User" />
                        <i
                          className="ni ni-send text-gray position-absolute mt-auto"
                          style={{ bottom: 0, right: 0 }}
                        />
                      </Media>
                      <Media className=" mt-4">
                        <span style={{ fontSize: '0.8rem' }}>
                          Clique na foto para altera-la!
                        </span>
                      </Media>
                    </Media>
                  </Col> */}
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Nome
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-username"
                            type="text"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.name}
                            error={!!formik.errors.name}
                            disabled={!profileEditable}
                          />
                          {formik.errors.name && formik.touched.name && (
                            <div className="input-feedback">
                              {formik.errors.name}
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Email
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-email"
                            type="email"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.email}
                            error={!!formik.errors.email}
                            disabled={!profileEditable}
                          />
                          {formik.errors.email && formik.touched.email && (
                            <div className="input-feedback">
                              {formik.errors.email}
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-new-password"
                          >
                            Nova senha
                          </label>
                          <InputPassword
                            className="form-control-alternative"
                            id="input-password"
                            placeholder=""
                            name="password"
                            autoComplete="new-password"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.password}
                            error={!!formik.errors.password}
                            disabled={!profileEditable}
                          />
                          {formik.errors.password &&
                            formik.touched.password && (
                              <div className="input-feedback">
                                {formik.errors.password}
                              </div>
                            )}
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Form>
              </FormikProvider>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Profile
