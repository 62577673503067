import React, { useState, useMemo, useEffect } from 'react'
import * as yup from 'yup'
import Select from 'react-select'
import { useFormik, FormikProvider } from 'formik'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Label,
  Form,
  FormGroup,
  Row,
  Col,
} from 'reactstrap'
import { alertDanger, alertSuccess } from 'utils/toast'
import api from 'configs/api'
import InputPassword from 'components/InputPassword'

const validationSchema = yup.object().shape({
  name: yup.string().required('Informe o nome!'),

  email: yup
    .string()
    .required('Informe o email!')
    .test('email', 'Digite um email válido', (value) =>
      value ? /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,8}$/i.test(value) : true
    ),
  // companies: yup
  // .array()
  // .test('test', 'Selecione pelo menos uma empresa', (value) =>
  //   value ? value.length > 0 : true
  // ),
  password: yup.string(),
})

const ModalRhAdmins = ({ modal, setModal, setLoading, admin, action }: any) => {
  const [companies, setCompanies] = useState<any[]>([])
  const [statusActive, setStatusActive] = useState(admin?.status === 'active')

  const initialValues = useMemo(
    () => ({
      name: '',
      email: '',
      password: '',
      companies: [],
    }),
    []
  )
  const formatItems = (items: any) => {
    if (items && items.length > 0) {
      return items.map((item: any) => ({
        value: item.id,
        label: item.legal_nature,
      }))
    }
    return []
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true)
      try {
        const companiesA = values?.companies.map((comp: any) => ({
          company_id: comp.value,
        }))
        if (admin?.id) {
          const res = await api.put(`company/v1/rh/${admin.id}/update`, {
            name: values.name,
            companies: companiesA,
            email: values.email,
            password: values.password,
            status: statusActive || !admin?.id ? 'active' : 'inactive',
          })
          action()
          alertSuccess(res?.data?.message || 'Sucesso!')
        } else {
          const res = await api.post(`company/v1/rh/create`, {
            name: values.name,
            email: values.email,
            companies: companiesA,
            password: values.password,
            status: statusActive || !admin?.id ? 'active' : 'inactive',
          })
          action()
          alertSuccess(res?.data?.message || 'Sucesso!')
        }
      } catch (err: any) {
        alertDanger(err?.response?.data?.message)
      }
      setModal(false)
      setLoading(false)
    },
  })

  const toggle = () => {
    formik.resetForm()
    setModal(!modal)
  }

  useEffect(() => {
    formik.setValues({
      name: admin?.name || '',
      email: admin?.email || '',
      companies: admin?.companies || [],
      password: '',
    })
  }, [admin])

  const getCompanies = async () => {
    setLoading(true)
    try {
      const { data } = await api.get(`manager/company`)
      setCompanies(formatItems(data?.companies))
      setModal(true)
    } catch (err: any) {
      console.log('error', err?.response?.data)
    }
    setLoading(false)
  }

  useEffect(() => {
    getCompanies()
  }, [])

  return (
    <Modal isOpen={modal} toggle={toggle} style={{ minWidth: 600 }}>
      <ModalHeader toggle={toggle}>
        <h3>
          {admin.id ? `Editar administrador` : `Cadastrar novo administrador`}
        </h3>
      </ModalHeader>
      <ModalBody className="p-4">
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit} autoComplete="off">
            <Row>
              <Col sm="6">
                <FormGroup>
                  <Label for="name">Nome</Label>
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    autoComplete="off"
                    disabled={!statusActive && admin.id}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    error={formik.errors.name}
                  />
                  {formik.errors.name && formik.touched.name && (
                    <div className="input-feedback">{formik.errors.name}</div>
                  )}
                </FormGroup>
              </Col>
              <Col sm="6">
                <FormGroup>
                  <Label htmlFor="email">Email</Label>
                  <Input
                    type="text"
                    name="email"
                    id="email"
                    autoComplete="off"
                    disabled={!statusActive && admin.id}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.errors.email}
                  />
                  {formik.errors.email && formik.touched.email && (
                    <div className="input-feedback">{formik.errors.email}</div>
                  )}
                </FormGroup>
              </Col>
              {admin.id && (
                <Col sm="6">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="new">
                      Nova Senha
                    </label>
                    <InputPassword
                      id="new"
                      placeholder=""
                      autocomplete="off"
                      name="password"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.password}
                      error={!!formik.errors.password}
                    />
                    {formik.errors.password && formik.touched.password && (
                      <div className="input-feedback">
                        {formik.errors.password}
                      </div>
                    )}
                  </FormGroup>
                </Col>
              )}
              {admin.id && (
                <Col sm="6">
                  <FormGroup
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                  >
                    <label
                      className="form-control-label"
                      htmlFor="input-status"
                    >
                      Status
                    </label>
                    <label className="custom-toggle">
                      <input
                        type="checkbox"
                        checked={statusActive}
                        onChange={() => {
                          // changeStatus(admin.id, admin.status)
                          setStatusActive(!statusActive)
                        }}
                      />
                      <span className="custom-toggle-slider rounded-circle" />
                    </label>
                  </FormGroup>
                </Col>
              )}

              <Col sm="12">
                <FormGroup>
                  <Label htmlFor="email">Empresas</Label>
                  <Select
                    placeholder="Selecione as empresas..."
                    isMulti
                    id="companies"
                    defaultValue={admin?.companies}
                    options={companies}
                    value={formik.values.companies}
                    onChange={(e: any) => {
                      formik.setValues({
                        ...formik.values,
                        companies: e,
                      })
                    }}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.companies && formik.touched.companies && (
                    <div className="input-feedback">
                      {formik.errors.companies}
                    </div>
                  )}
                </FormGroup>
              </Col>
            </Row>

            <Row className="mt-2">
              <Col className="d-flex justify-content-end">
                <Button
                  type="submit"
                  color="secondary"
                  disabled={
                    !formik.isValid ||
                    (statusActive && formik.values.companies.length === 0)
                  }
                  onClick={!formik.isValid ? toggle : () => {}}
                >
                  {admin?.id ? `Salvar` : `Cadastrar`}
                </Button>
                <Button outline color="primary" onClick={toggle}>
                  Cancelar
                </Button>
              </Col>
            </Row>
          </Form>
        </FormikProvider>
      </ModalBody>
    </Modal>
  )
}
export default ModalRhAdmins
