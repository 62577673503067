import React, { useMemo, useState } from 'react'
import * as yup from 'yup'
import {
  cpfCnpjMask,
  cpfCnpjUnmask,
  dateMask,
  telephoneMask,
  telephoneUnmask,
  cpfValidator,
} from 'js-essentials-functions'
import classnames from 'classnames'

import { useFormik, FormikProvider } from 'formik'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Label,
  Form,
  FormGroup,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap'
import api from 'configs/api'
import { alertDanger, alertSuccess } from 'utils/toast'

import { department } from 'utils/departament'

import moment from 'moment'

function formatCPF(value: any) {
  const numericValue = value.replace(/\D/g, '')
  if (numericValue.length < 11) {
    return numericValue
  }
  return `${numericValue.slice(0, 3)}.${numericValue.slice(
    3,
    6
  )}.${numericValue.slice(6, 9)}-${numericValue.slice(9)}`
}

const schemaHolder = yup.object().shape({
  name: yup.string().required('Informe o nome!'),
  cpf: yup
    .string()
    .required('Informe o CPF!')
    .min(14, 'CPF inválido!')
    .test('test', 'CPF inválido!', (value) => {
      if (!value) return false
      const formattedValue = formatCPF(value)
      return cpfValidator(formattedValue)
    }),
  birthdate: yup
    .string()
    .required('Informe a data de nascimento!')
    .min(10, 'Data de nascimento inválida')
    .test('birthdate', 'Escolha uma data de nascimento válida', (value) =>
      moment(value, 'DD/MM/YYYY', true).isValid()
    ),
  email: yup
    .string()
    .required('Informe o email!')
    .test('email', 'Digite um email válido', (value) =>
      value ? /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) : true
    ),
  gender: yup.string().required('Informe o Gênero!'),
  department: yup.string().required('Informe o Departamento!'),
  cellphone: yup
    .string()
    .required('Informe o telefone!')
    .test('cellphone', 'Digite um telefone válido', (value) =>
      value
        ? /^(\d{2})\D*(\d{5}|\d{4})\D*(\d{4})$/i.test(telephoneUnmask(value))
        : true
    ),
})
const schemaDependent = yup.object().shape({
  name: yup.string().required('Informe o nome!'),
  cpf: yup
    .string()
    .required('Informe o CPF!')
    .min(14, 'CPF inválido!')
    .test('test', 'CPF inválido!', (value) => {
      if (!value) return false
      const formattedValue = formatCPF(value)
      return cpfValidator(formattedValue)
    }),
  cpf_holder: yup
    .string()
    .required('Informe o CPF do titular!')
    .min(14, 'CPF inválido!')
    .test('test', 'CPF inválido!', (value) => {
      if (!value) return false
      const formattedValue = formatCPF(value)
      return cpfValidator(formattedValue)
    }),
  birthdate: yup
    .string()
    .required('Informe a data de nascimento!')
    .min(10, 'Data de nascimento inválida')
    .test('birthdate', 'Escolha uma data de nascimento válida', (value) =>
      moment(value, 'DD/MM/YYYY', true).isValid()
    ),
  email: yup
    .string()
    .required('Informe o email!')
    .test('email', 'Digite um email válido', (value) =>
      value ? /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) : true
    ),
  gender: yup.string().required('Informe o Gênero!'),
  cellphone: yup
    .string()
    .required('Informe o telefone!')
    .test('cellphone', 'Digite um telefone válido', (value) =>
      value
        ? /^(\d{2})\D*(\d{5}|\d{4})\D*(\d{4})$/i.test(telephoneUnmask(value))
        : true
    ),
})

const NewUserB2b = ({ modal, setModal, setLoading, company, action }: any) => {
  const [tab, setTab] = useState(1)
  const initialValues = useMemo(
    () => ({
      name: '',
      cpf: '',
      birthdate: '',
      email: '',
      gender: '',
      cellphone: '',
      department: '',
    }),
    []
  )
  const initialValuesDependent = useMemo(
    () => ({
      name: '',
      cpf: '',
      cpf_holder: '',
      birthdate: '',
      email: '',
      gender: '',
      cellphone: '',
    }),
    []
  )

  const formik = useFormik({
    initialValues,
    validationSchema: schemaHolder,
    onSubmit: async (values) => {
      setLoading(true)
      try {
        await api.post('manager/client/b2b', {
          user: {
            name: values.name,
            document: cpfCnpjUnmask(values.cpf),
            birthdate: values.birthdate,
            email: values.email,
            cellphone: telephoneUnmask(values.cellphone),
            gender: values.gender,
          },
          company: {
            id: company.uuid,
            plan_id: company?.plan_id,
            status_plan: 'active',
          },
          professional_informations: {
            department: values.department,
          },
        })
        action()
        alertSuccess('Usuário cadastrado com sucesso!')
      } catch (err: any) {
        alertDanger(err?.response?.data?.message)
      }
      setModal(false)
      setLoading(false)
    },
  })
  const formikDependent = useFormik({
    initialValues: initialValuesDependent,
    validationSchema: schemaDependent,
    onSubmit: async (values) => {
      setLoading(true)
      try {
        await api.post('manager/client/b2b/dependent', {
          user: {
            holder_document: cpfCnpjUnmask(values.cpf_holder),
            name: values.name,
            document: cpfCnpjUnmask(values.cpf),
            birthdate: values.birthdate,
            email: values.email,
            cellphone: telephoneUnmask(values.cellphone),
            gender: values.gender,
          },
        })
        action()
        alertSuccess('Usuário cadastrado com sucesso!')
      } catch (err: any) {
        alertDanger(err?.response?.data?.message)
      }
      setModal(false)
      setLoading(false)
    },
  })

  const toggle = () => {
    formik.resetForm()
    formikDependent.resetForm()
    setModal(!modal)
  }

  const UserHolder = () => (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit} autoComplete="off">
        <Row className="d-flex">
          <Col>
            <FormGroup>
              <Label for="name">Nome do usuário</Label>
              <Input
                type="text"
                name="name"
                id="name"
                autoComplete="off"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.name}
                error={!!formik.errors.name}
              />
              {formik.errors.name && formik.touched.name && (
                <div className="input-feedback">{formik.errors.name}</div>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row className=" d-flex justify-content-between align-items-center">
          <Col sm="6">
            <FormGroup>
              <Label htmlFor="cpf"> CPF</Label>
              <Input
                type="text"
                name="cpf"
                id="cpf"
                autoComplete="off"
                maxLength={14}
                value={cpfCnpjMask(formik.values.cpf)}
                onChange={(e) => {
                  const newValue = formatCPF(e.target.value) // Formata o valor antes de definir no estado do Formik
                  formik.setFieldValue('cpf', newValue)
                }}
                onBlur={formik.handleBlur}
              />
              {formik.errors.cpf && formik.touched.cpf && (
                <div className="input-feedback">{formik.errors.cpf}</div>
              )}
            </FormGroup>
          </Col>
          <Col sm="6">
            <FormGroup>
              <Label htmlFor="birthdate"> Data de nascimento</Label>
              <Input
                type="text"
                name="birthdate"
                id="birthdate"
                autoComplete="off"
                maxLength={10}
                value={dateMask(formik.values.birthdate)}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.birthdate && formik.touched.birthdate && (
                <div className="input-feedback">{formik.errors.birthdate}</div>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row className="mt-1 d-flex ">
          <Col sm="6">
            <FormGroup>
              <Label htmlFor="cellphone">Celular</Label>
              <Input
                type="text"
                name="cellphone"
                id="cellphone"
                autoComplete="off"
                maxLength={15}
                value={telephoneMask(formik.values.cellphone)}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.cellphone && formik.touched.cellphone && (
                <div className="input-feedback">{formik.errors.cellphone}</div>
              )}
            </FormGroup>
          </Col>
          <Col sm="6">
            <FormGroup>
              <Label htmlFor="plan">Gênero</Label>
              <Input
                type="select"
                id="gender"
                name="gender"
                onChange={formik.handleChange}
                value={formik.values.gender}
                onBlur={formik.handleBlur}
              >
                <option value="" selected>
                  Selecione...
                </option>
                <option value="male">Masculino</option>
                <option value="female">Feminino</option>
                <option value="other">Outros</option>
              </Input>
              {formik.errors.gender && formik.touched.gender && (
                <div className="input-feedback">{formik.errors.gender}</div>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row className="mt-1 d-flex justify-content-between align-items-center">
          <Col sm="6">
            <FormGroup>
              <Label htmlFor="email"> Email</Label>
              <Input
                type="text"
                name="email"
                id="email"
                autoComplete="off"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.email && formik.touched.email && (
                <div className="input-feedback">{formik.errors.email}</div>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row className="mt-1 d-flex justify-content-between align-items-center">
          <Col>
            <FormGroup>
              <Label htmlFor="department">Departamento</Label>
              <Input
                type="select"
                name="department"
                id="department"
                autoComplete="off"
                value={formik.values.department}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                {department?.map((e) => (
                  <option value={e.value} key={e.key}>
                    {e.title}
                  </option>
                ))}
                <option value="other">Outros</option>
              </Input>
              {formik.errors.department && formik.touched.department && (
                <div className="input-feedback">{formik.errors.department}</div>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col className="d-flex justify-content-end">
            <Button
              type="submit"
              color="secondary"
              disabled={!formik.isValid}
              onClick={!formik.isValid ? toggle : () => {}}
            >
              Salvar
            </Button>
            <Button outline color="primary" onClick={toggle}>
              Cancelar
            </Button>
          </Col>
        </Row>
      </Form>
    </FormikProvider>
  )
  const UserDependent = () => (
    <FormikProvider value={formikDependent}>
      <Form onSubmit={formikDependent.handleSubmit} autoComplete="off">
        <Row className="d-flex">
          <Col sm="6">
            <FormGroup>
              <Label htmlFor="cpf_holder"> CPF do Titular</Label>
              <Input
                type="text"
                name="cpf_holder"
                id="cpf_holder"
                autoComplete="off"
                maxLength={14}
                value={cpfCnpjMask(formikDependent.values.cpf_holder)}
                onChange={(e) => {
                  const newValue = formatCPF(e.target.value) // Formata o valor antes de definir no estado do Formik
                  formikDependent.setFieldValue('cpf_holder', newValue)
                }}
                onBlur={formikDependent.handleBlur}
              />
              {formikDependent.errors.cpf_holder &&
                formikDependent.touched.cpf_holder && (
                  <div className="input-feedback">
                    {formikDependent.errors.cpf_holder}
                  </div>
                )}
            </FormGroup>
          </Col>
          <Col sm="6">
            <FormGroup>
              <Label htmlFor="cpf">CPF do Dependente</Label>
              <Input
                type="text"
                name="cpf"
                id="cpf"
                autoComplete="off"
                maxLength={14}
                value={cpfCnpjMask(formikDependent.values.cpf)}
                onChange={(e) => {
                  const newValue = formatCPF(e.target.value) // Formata o valor antes de definir no estado do Formik
                  formikDependent.setFieldValue('cpf', newValue)
                }}
                onBlur={formikDependent.handleBlur}
              />
              {formikDependent.errors.cpf && formikDependent.touched.cpf && (
                <div className="input-feedback">
                  {formikDependent.errors.cpf}
                </div>
              )}
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup>
              <Label for="name">Nome do Dependente</Label>
              <Input
                type="text"
                name="name"
                id="name"
                autoComplete="off"
                onBlur={formikDependent.handleBlur}
                onChange={formikDependent.handleChange}
                value={formikDependent.values.name}
                error={!!formikDependent.errors.name}
              />
              {formikDependent.errors.name && formikDependent.touched.name && (
                <div className="input-feedback">
                  {formikDependent.errors.name}
                </div>
              )}
            </FormGroup>
          </Col>
          <Col sm="6">
            <FormGroup>
              <Label htmlFor="birthdate"> Data de nascimento</Label>
              <Input
                type="text"
                name="birthdate"
                id="birthdate"
                autoComplete="off"
                maxLength={10}
                value={dateMask(formikDependent.values.birthdate)}
                onChange={formikDependent.handleChange}
                onBlur={formikDependent.handleBlur}
              />
              {formikDependent.errors.birthdate &&
                formikDependent.touched.birthdate && (
                  <div className="input-feedback">
                    {formikDependent.errors.birthdate}
                  </div>
                )}
            </FormGroup>
          </Col>
          <Col sm="6">
            <FormGroup>
              <Label htmlFor="cellphone">Celular</Label>
              <Input
                type="text"
                name="cellphone"
                id="cellphone"
                autoComplete="off"
                maxLength={15}
                value={telephoneMask(formikDependent.values.cellphone)}
                onChange={formikDependent.handleChange}
                onBlur={formikDependent.handleBlur}
              />
              {formikDependent.errors.cellphone &&
                formikDependent.touched.cellphone && (
                  <div className="input-feedback">
                    {formikDependent.errors.cellphone}
                  </div>
                )}
            </FormGroup>
          </Col>
          <Col sm="6">
            <FormGroup>
              <Label htmlFor="email"> Email</Label>
              <Input
                type="text"
                name="email"
                id="email"
                autoComplete="off"
                value={formikDependent.values.email}
                onChange={formikDependent.handleChange}
                onBlur={formikDependent.handleBlur}
              />
              {formikDependent.errors.email &&
                formikDependent.touched.email && (
                  <div className="input-feedback">
                    {formikDependent.errors.email}
                  </div>
                )}
            </FormGroup>
          </Col>
          <Col sm="6">
            <FormGroup>
              <Label htmlFor="plan">Gênero</Label>
              <Input
                type="select"
                id="gender"
                name="gender"
                onChange={formikDependent.handleChange}
                value={formikDependent.values.gender}
                onBlur={formikDependent.handleBlur}
              >
                <option value="" selected>
                  Selecione...
                </option>
                <option value="male">Masculino</option>
                <option value="female">Feminino</option>
                <option value="other">Outros</option>
              </Input>
              {formikDependent.errors.gender &&
                formikDependent.touched.gender && (
                  <div className="input-feedback">
                    {formikDependent.errors.gender}
                  </div>
                )}
            </FormGroup>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col className="d-flex justify-content-end">
            <Button
              type="submit"
              color="secondary"
              disabled={!formikDependent.isValid}
              onClick={!formikDependent.isValid ? toggle : () => {}}
            >
              Salvar
            </Button>
            <Button outline color="primary" onClick={toggle}>
              Cancelar
            </Button>
          </Col>
        </Row>
      </Form>
    </FormikProvider>
  )

  return (
    <div>
      <Button
        sm="3"
        color="secondary"
        onClick={() => {
          toggle()
        }}
      >
        <i className="fas fa-plus" />
        {` Cadastrar`}
      </Button>
      <Modal isOpen={modal} toggle={toggle} style={{ minWidth: 500 }}>
        <ModalHeader toggle={toggle}>
          <h3>Cadastrar novo usuário</h3>
        </ModalHeader>

        <Nav
          className="nav-fill flex-column flex-sm-row"
          id="tabs-text"
          pills
          role="tablist"
        >
          <NavItem>
            <NavLink
              className={classnames('ml-3', {
                active: tab === 1,
                width: '100%',
              })}
              onClick={() => setTab(1)}
              href="#titular"
              role="tab"
            >
              Titular
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames('mr-3', {
                active: tab === 2,
                width: '100%',
              })}
              onClick={() => setTab(2)}
              href="#dependente"
              role="tab"
            >
              Dependente
            </NavLink>
          </NavItem>
        </Nav>
        <ModalBody className="p-4">
          <TabContent activeTab={`tabs${tab}`}>
            <TabPane tabId="tabs1">{UserHolder()}</TabPane>
            <TabPane tabId="tabs2">{UserDependent()}</TabPane>
          </TabContent>
        </ModalBody>
      </Modal>
    </div>
  )
}
export default NewUserB2b
