import React, { useState } from 'react'

import { alertDanger, alertSuccess } from 'utils/toast'

import {
  Card,
  CardHeader,
  Table,
  Row,
  Col,
  Button,
  FormGroup,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap'

import PaginationComponent from 'components/PaginationComponent'
import Loading from 'components/Loading'
import moment from 'moment'

import api from 'configs/api'

import useFetch from 'hooks/useFetch'
import { useSWRConfig } from 'swr'
import { ModalCreateEvent } from '../Components/ModalCreateEvent'

const Events = () => {
  const [loading, setLoading] = useState(false)
  const [type, setType] = useState('all')
  const [modalStatus, setModalStatus] = useState(false)
  const [modalNewEvent, setModalNewEvent] = useState(false)
  const [evento, setEvento] = useState<any>({
    id: '',
    title: '',
    status: '',
  })

  const { mutate } = useSWRConfig()
  const [page, setPage] = useState(1)
  const limit = 6

  const { data, isLoading } = useFetch(
    `/manager/events?page=${page}&take=${limit}&status=${type}`
  )

  const getStatus: any = {
    pending: 'Em andamento',
    canceled: 'Cancelado',
    completed: 'Expirado',
  }

  const action = () => {
    mutate(`/manager/events?page=${page}&take=${limit}&status=${type}`)
  }

  const changeStatus = async () => {
    setLoading(true)
    try {
      const res = await api.put(`/manager/events/${evento?.id}`, {
        status: evento.status,
      })
      alertSuccess(res.data.message)
      setModalStatus(false)
      action()
    } catch (error) {
      alertDanger('Ocorreu algum erro, tente novamente!')
    }
    setLoading(false)
  }

  const contentModalStatus = () => (
    <Modal
      isOpen={modalStatus}
      toggle={() => {
        setModalStatus(!modalStatus)
      }}
    >
      <ModalHeader>
        <div>
          <h3>{`Você deseja alterar o status do evento "${evento?.title}"?`}</h3>
        </div>
      </ModalHeader>
      <ModalBody className="p-4">
        <Row>
          <Col sm="12">
            <FormGroup className="mb-0">
              <Input
                type="select"
                style={{ paddingRight: 10 }}
                onChange={(e) =>
                  setEvento({ ...evento, status: e.target.value })
                }
                value={evento.status}
              >
                <option value="pending">Em andamento</option>
                <option value="completed">Expirado</option>
                <option value="canceled">Cancelado</option>
              </Input>
            </FormGroup>
          </Col>
        </Row>
        <Row className="mt-3 d-flex justify-content-between">
          <Col sm="6">
            <Button
              color="primary"
              outline
              onClick={() => {
                setModalStatus(false)
              }}
            >
              Cancelar
            </Button>
          </Col>
          <Col sm="6">
            <Button
              style={{ minWidth: 200 }}
              color="secondary"
              onClick={() => {
                changeStatus()
              }}
            >
              Confirmar
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )

  return (
    <div>
      {isLoading && <Loading title="Buscando Eventos..." />}
      {loading && <Loading title="Aguarde..." />}
      {contentModalStatus()}
      <ModalCreateEvent
        modal={modalNewEvent}
        setModal={setModalNewEvent}
        setLoading={setLoading}
        action={action}
      />
      <Card className="shadow">
        <CardHeader className="bg-transparent border-0">
          <Row className="align-items-center d-flex justify-content-between">
            <Col sm="3" className="mb-2">
              <h3 className="mb-0 text-black">Eventos</h3>
            </Col>
          </Row>
          <Row className="mt-3 align-items-end d-flex justify-content-between">
            <Col sm="auto" className="justify-content-start">
              <Button color="secondary" onClick={() => setModalNewEvent(true)}>
                <i className="fas fa-plus" />
              </Button>
            </Col>
            <Col sm="3">
              <FormGroup className="mb-0">
                <Input
                  type="select"
                  style={{ paddingRight: 10 }}
                  onChange={(e) => setType(e.target.value)}
                  value={type}
                >
                  <option value="all">Todos</option>
                  <option value="pending">Em andamento</option>
                  <option value="completed">Expirados</option>
                  <option value="canceled">Cancelados</option>
                </Input>
              </FormGroup>
            </Col>
          </Row>
        </CardHeader>
        <Row className="mt-0">
          <div className="col">
            <Card className="shadow">
              <Table
                className="align-items-center table-flush"
                responsive
                hover
              >
                <thead className="thead-light">
                  <tr>
                    <th scope="col">titulo</th>
                    <th scope="col">banner</th>
                    <th scope="col" colSpan={1}>
                      data do evento
                    </th>
                    <th scope="col">status</th>
                    <th scope="col">ações</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.events?.map((event: any) => (
                    <tr key={event.id} style={{ cursor: 'pointer' }}>
                      <th scope="row">{event.title}</th>
                      <td>
                        <img
                          src={event.banner}
                          alt="Banner"
                          style={{
                            backgroundSize: 'cover',
                            maxWidth: 300,
                            maxHeight: 100,
                          }}
                        />
                      </td>
                      <td>
                        {moment(new Date(event.date))
                          .add(3, 'hours')
                          .format('DD/MM/YYYY')}
                      </td>
                      <td>{getStatus[event.status]}</td>
                      <td>
                        <Button
                          size="sm"
                          onClick={() => {
                            setEvento(event)
                            setModalStatus(true)
                          }}
                        >
                          Editar
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <PaginationComponent
                numberOfPages={Math.ceil(data?.total / limit)}
                page={page}
                setPage={setPage}
              />
            </Card>
          </div>
        </Row>
      </Card>
    </div>
  )
}

export default Events
