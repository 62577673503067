import React, { useState, useEffect } from 'react'
import {
  Card,
  CardHeader,
  Row,
  Col,
  Table,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  FormGroup,
  Container,
} from 'reactstrap'
import ReactDatetime from 'react-datetime'

import { alertDanger } from 'utils/toast'
import api from 'configs/api'
import Loading from 'components/Loading'
import moment from 'moment'

function Metrics({ select }: any) {
  const [dados, setDados] = useState<any>()
  const [loading, setLoading] = useState(false)
  const [dates, setDates] = useState<any>({
    start: moment().format('01/MM/YYYY'),
    end: moment().format('DD/MM/YYYY'),
  })

  const getDate = async () => {
    const { start, end } = dates

    if (!start && end) {
      return alertDanger('Preencha os períodos!')
    }
    setLoading(true)
    try {
      const { data } = await api.get(
        `/manager/reports/international/users?start=${start}&end=${end}&country=${select?.sigla}`
      )

      setDados(data)
    } catch (error) {
      alertDanger('Ocorreu algum erro')
    }
    setLoading(false)
    return true
  }

  useEffect(() => {
    getDate()
  }, [dates, select])

  return (
    <>
      {loading && <Loading title="Aguarde..." />}

      <Container>
        <Row className="d-flex">
          <Col className="mb-4 d-flex align-items-stretch">
            <Card className="shadow h-100 w-100">
              <Row className="align-items-center mt-4 ml-2 mr-2">
                <Col sm="auto">
                  <p style={{ fontWeight: 600 }}>Período:</p>
                </Col>
                <Col xs={12} md={4}>
                  <FormGroup>
                    <h5 className="mb-2 text-black">Data inicial</h5>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-calendar-grid-58" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <ReactDatetime
                        inputProps={{
                          placeholder: 'Selecione a data inicial',
                        }}
                        value={dates.start}
                        dateFormat="DD/MM/YYYY"
                        timeFormat={false}
                        closeOnSelect
                        onChange={(e: any) => {
                          setDates({
                            ...dates,
                            start: moment(e).format('DD/MM/YYYY'),
                          })
                        }}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col xs={12} md={4}>
                  <FormGroup>
                    <h5 className="mb-2 text-black">Data final</h5>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-calendar-grid-58" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <ReactDatetime
                        inputProps={{
                          placeholder: 'Selecione a data final',
                        }}
                        value={dates.end}
                        dateFormat="DD/MM/YYYY"
                        timeFormat={false}
                        closeOnSelect
                        onChange={(e: any) => {
                          setDates({
                            ...dates,
                            end: moment(e).format('DD/MM/YYYY'),
                          })
                        }}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>

              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Col sm="12">
                    <h3 className="mb-0">
                      {select?.sigla ? select?.name : 'País'}
                    </h3>
                  </Col>
                </Row>
              </CardHeader>
              <Table
                className="align-items-center table-flush d-flex"
                responsive
              >
                <Col>
                  <table style={{ width: '100%' }}>
                    <tr>
                      <th>Novas estrelas</th>
                      <td>{dados?.usersTotal}</td>
                    </tr>
                    <tr>
                      <th>Compra de consulta</th>
                      <td>{dados?.productsPurchases}</td>
                    </tr>
                    <tr>
                      <th>Consultas agendadas</th>
                      <td>{dados?.schedulingPending}</td>
                    </tr>
                    <tr>
                      <th>Consultas finalizadas</th>
                      <td>{dados?.schedulingFinished}</td>
                    </tr>
                  </table>
                </Col>
              </Table>
            </Card>
          </Col>

          <Col className="mb-4 d-flex align-items-stretch">
            <Card className="shadow h-100 w-100">
              <CardHeader className="border-0">
                <Row className=" align-items-center d-flex">
                  <Col>
                    <h3 className="mb-0">
                      {select?.sigla
                        ? `Dados gerais ${select?.name}`
                        : 'Dados gerais País'}
                    </h3>
                  </Col>
                </Row>
              </CardHeader>
              <Table
                className="align-items-center table-flush d-flex"
                responsive
              >
                <Col>
                  <table style={{ width: '100%' }}>
                    <tr>
                      <th scope="row">Total de estrelas</th>
                      <td>{dados?.allUsers}</td>
                    </tr>
                    <tr>
                      <th scope="row">Total de compra de consulta</th>
                      <td>{dados?.allProduct}</td>
                    </tr>
                    <tr>
                      <th scope="row">Total de consultas finalizadas</th>
                      <td>{dados?.allSchedulingFinished}</td>
                    </tr>
                  </table>
                </Col>
              </Table>
            </Card>
          </Col>
        </Row>
        <Card className="border-0 shadow">
          <CardHeader className="border-0">
            <Row className=" align-items-center d-flex">
              <Col sm="auto">
                <h3 className="mb-0">Novos cadastros do mês</h3>
              </Col>
            </Row>
          </CardHeader>
          <Table className="align-items-center table-flush d-flex" responsive>
            <Col>
              <table style={{ width: '100%' }}>
                <tr>
                  <th>Nome</th>
                  <th>E-mail</th>
                  <th>Telefone</th>
                  <th>Pais</th>
                  <th>Data de Cadastro</th>
                </tr>

                {dados?.users.map((user: any, key: any) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <tr key={key}>
                    <td>{user.name}</td>
                    <td>{user.email}</td>
                    <td>
                      {user.ddi}
                      {user.cellphone}
                    </td>
                    <td>{user.country}</td>
                    <td>{moment(user.created_at).format('DD/MM/YYYY')}</td>
                  </tr>
                ))}
              </table>
            </Col>
          </Table>
        </Card>
      </Container>
    </>
  )
}

export default Metrics
