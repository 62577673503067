/* eslint-disable radix */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback } from 'react'
import { cpfCnpjMask } from 'js-essentials-functions'
import * as EmailValidator from 'email-validator'
// eslint-disable-next-line camelcase
import { telefone_validation } from 'utils/cellphoneValidator'
import { validarCPF } from 'utils/cpfValidator'

import {
  Button,
  Card,
  CardHeader,
  Col,
  CardBody,
  Input,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap'
import api from 'configs/api'
import PaginationComponent from 'components/PaginationComponent'
import { useDropzone } from 'react-dropzone'
import { alertWarning, alertSuccess } from 'utils/toast'
import Loading from 'components/Loading'
import ModalContent from 'components/Modal'
import ReactDropZoneInput from 'components/ReactDropzoneInput'
import { useLocation } from 'react-router-dom'
import { read, utils } from 'xlsx'

import NewUserB2b from '../Components/ModalCreateUser'
import ModalCompanies from '../Components/ModalCompanies'

const CollaboratorsCompany = ({ company }: any) => {
  const [usesCompany, setUsersCompany] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [name, setName] = useState('')
  const [nameCompany, setNameCompany] = useState('')
  const [fileToUpload, setFileToUpload] = useState<any | null>(null)
  const [modal, setModal] = useState(false)
  const [usersinvalid, setUsersInvalid] = useState<any[]>([])

  const [modalCompany, setModalCompany] = useState(false)

  const [showModal, setShowModal] = useState(false)
  const [modalRemove, setModalRemove] = useState(false)
  const [userSelected, setUserSelected] = useState('')

  const params: any = useLocation()

  const companyId =
    params.pathname.split('/')[params.pathname.split('/').length - 1]
  // Paginação
  const [paginationOn, setPaginationOn] = useState(true)
  const [numberOfPages, setNumberOfPages] = useState(0)
  const [page, setPage] = useState(1)
  const limit = 10
  const [typeUser, setTypeUser] = useState<
    'titular' | 'dependente' | 'remove' | ''
  >('')

  const [invalids, setInvalids] = useState<any>(false)

  // react-dropzone config
  const onDrop = useCallback(
    async (acceptedFiles) => {
      const file = acceptedFiles[0]

      if (typeUser === 'remove') {
        return setFileToUpload(file)
      }

      return new Promise((resolve) => {
        const reader = new FileReader()
        reader.onload = (e: any) => {
          const data = e.target.result
          const workbook = read(data, { type: 'binary' })

          const sheetName = workbook.SheetNames[0]
          const worksheet: any = workbook.Sheets[sheetName]
          const range = utils.decode_range(worksheet['!ref'])
          range.s.r = 2
          worksheet['!ref'] = utils.encode_range(range)

          const novadata = utils
            .sheet_to_csv(worksheet, { FS: ',' })
            .replace(/^(?:,|\n)+|(?:,|\n)+$/g, '')

          if (novadata) {
            const lines = novadata.toString().split('\n')
            const users: string[] = []
            lines.map((line: string, index: number) => {
              if (index > 0) {
                const result = line.split(',')

                if (typeUser === 'titular') {
                  setFileToUpload(file)
                }

                if (typeUser === 'dependente') {
                  const emailValidator = EmailValidator.validate(
                    result[2].trim()
                  )

                  if (result[1]?.trim().length < 5) {
                    users.push(`${result[3]} (Nome inválido)`)
                    setInvalids(true)
                  } else if (!validarCPF(result[3])) {
                    users.push(`${result[1]} (CPF inválido)`)
                    setInvalids(true)
                  } else if (!emailValidator) {
                    users.push(`${result[1]} (Email inválido)`)
                    setInvalids(true)
                  } else if (!telefone_validation(result[4])) {
                    users.push(`${result[1]} (Telefone inválido)`)
                    setInvalids(true)
                  }
                }
              }
            })

            setUsersInvalid(users)
          }

          resolve(novadata)
        }
        reader.readAsBinaryString(file)
        setFileToUpload(file)
      })
    },
    [typeUser]
  )

  const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
    useDropzone({
      onDrop,
      accept:
        typeUser === 'remove'
          ? '.csv, application/vnd.ms-excel, text/csv'
          : '.xlsx,  text/xlsx',
      multiple: false,
    })

  const getCompany = async (nameUser = name, numberPage = page) => {
    setName('')
    setLoading(true)
    try {
      const response = await api.get(
        `/manager/company/${companyId}?user_name=${nameUser}&limit=${limit}&page=${numberPage}`
      )
      setNameCompany(response.data.item.legal_nature)
      const datas = response.data.item.users.map((data: any) => ({
        id: data.id,
        name: data.name,
        document: data.document,
        status: data.status === 'active' ? 'Ativo' : 'Inativo',
        email: data.email,
        is_holder: data.is_holder,
      }))

      if (
        Math.ceil(response.data.totalUsers / limit) === 1 ||
        datas.length === 0
      ) {
        setPaginationOn(false)
      } else {
        setPaginationOn(true)
      }

      setNumberOfPages(Math.ceil(response.data.totalUsers / limit))
      setUsersCompany(datas)
    } catch (err: any) {
      console.log('error', err)
    }

    setLoading(false)
  }

  useEffect(() => {
    getCompany()
  }, [page])

  const handleSubmit = async () => {
    try {
      if (fileToUpload) {
        setLoading(true)
        const formData = new FormData()
        formData.append('file', fileToUpload)
        formData.append('company_id', companyId)

        if (typeUser === 'titular') {
          formData.append('plan_id', company?.plan_id)
          formData.append('status_plan', 'active')
        }
        let url = ''
        if (typeUser === 'titular') {
          url = 'manager/b2b/import?internal=true'
        } else if (typeUser === 'dependente') {
          url = 'manager/b2b/import-dependents?internal=true'
        } else if (typeUser === 'remove') {
          url = 'manager/b2b/remove'
        }

        const { data } = await api.post(url, formData)
        if (data?.item?.code === 'NotFound') {
          alertWarning(data?.item?.message || 'CSV vazio')
        } else if (data?.item?.usersErrors) {
          const invalidCpfs = data.item.usersErrors.map((item: any) => item)
          setUsersInvalid(invalidCpfs)
        } else {
          acceptedFiles.shift()
          setShowModal(false)
          getCompany()
          alertSuccess(data?.item?.message || 'Sucesso!')
        }
        setLoading(false)
      } else {
        alertWarning('É necessário informar um arquivo.')
      }
    } catch (err: any) {
      alertWarning(err?.response?.data?.message)
    } finally {
      setLoading(false)
    }
  }
  const handleRemove = async () => {
    setLoading(true)
    try {
      const response = await api.delete(`/manager/client/b2b/${userSelected}`)
      alertSuccess(response?.data?.message || 'Usuário removido com sucesso')
      setLoading(false)

      getCompany()
    } catch (err: any) {
      alertWarning(err?.response?.data?.message || 'Erro ao remover usuário')
      setLoading(false)
    }
    setModalRemove(false)
  }

  const ContentCpfsInvalid = () => (
    <div className="d-flex flex-column h-100 justify-content-space-between">
      <div className="text-center" style={{ height: '20%' }}>
        <h1 className="text-uppercase">Dados inválidos</h1>
        <p className="font-weight-bold text-muted">
          {invalids && 'Os seguintes usuários possuem dados inválidos:'}
        </p>
      </div>
      <div
        className="d-flex flex-column h-100 justify-content-center"
        style={{ flex: 1, height: '68%', overflowY: 'auto' }}
      >
        <ul>
          {typeUser === 'titular' &&
            usersinvalid.map((item) => (
              <li key={item} className="font-italic">
                {item.user}
                <span> - </span>
                {item?.errro?.map((error: any, i: number) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <span key={i}>
                    {error}
                    {i < item.errro.length - 1 && ' '}
                  </span>
                ))}
              </li>
            ))}

          {typeUser === 'dependente' &&
            usersinvalid.map((item) => (
              <li key={item} className="font-italic">
                {item}
              </li>
            ))}
        </ul>
      </div>
      <div
        className="d-flex align-items-end justify-content-end"
        style={{ height: '12%' }}
      >
        <div>
          <Button
            disabled={!fileToUpload}
            onClick={() => window.location.reload()}
          >
            OK
          </Button>
        </div>
      </div>
    </div>
  )

  const contentModalRemove = () => (
    <Modal
      isOpen={modalRemove}
      toggle={() => {
        setModalRemove(modalRemove)
      }}
    >
      <ModalHeader>
        <h3>Você deseja realmente remover esse usuário dessa empresa?</h3>
      </ModalHeader>
      <ModalBody className="p-4 d-flex justify-content-between">
        <Button
          color="primary"
          onClick={() => {
            handleRemove()
          }}
        >
          Sim
        </Button>
        <Button
          style={{ minWidth: 200 }}
          color="secondary"
          onClick={() => {
            setModalRemove(false)
          }}
        >
          Não
        </Button>
      </ModalBody>
    </Modal>
  )

  return (
    <div>
      {loading && <Loading title="Aguarde..." />}
      <CardHeader
        className="bg-transparent border-0"
        style={{ minHeight: 100 }}
      >
        <Row className="mt-3 align-items-center d-flex justify-content-between">
          <Col sm="auto" className="mb-2">
            <h3 className="mb-0 text-black">{`${nameCompany} | Colaboradores`}</h3>
          </Col>
          <Col sm="auto" className="align-items-start d-flex">
            <Button
              style={{ minWidth: 150 }}
              color="secondary"
              onClick={() => {
                setTypeUser('titular')
                setShowModal(true)
              }}
            >
              Importar Titulares
            </Button>
            <Button
              sm="auto"
              style={{ minWidth: 220 }}
              color="secondary"
              onClick={() => {
                setTypeUser('dependente')
                setShowModal(true)
              }}
            >
              Importar Dependentes
            </Button>
            <Button
              sm="auto"
              style={{ minWidth: 220 }}
              color="primary"
              outline
              onClick={() => {
                setTypeUser('remove')
                setShowModal(true)
              }}
            >
              Remover Titulares
            </Button>
          </Col>
        </Row>
      </CardHeader>
      <CardBody className="shadow">
        <Row className="pt-2 pb-2 align-items-center d-flex justify-content-between">
          <Col sm="auto" className="mb-2">
            <NewUserB2b
              modal={modal}
              setModal={setModal}
              setLoading={setLoading}
              company={{ uuid: companyId, plan_id: company?.plan_id }}
              action={getCompany}
            />
          </Col>
          <Col sm="auto" className="d-flex justify-content-end">
            <Col xs="8">
              <Input
                type="text"
                id="filtrar-cpf"
                maxLength={14}
                value={name}
                placeholder="Filtrar pelo nome..."
                onChange={(e) => setName(e.target.value)}
              />
            </Col>
            <Col xs="auto">
              <Button
                color="secondary"
                onClick={() => {
                  getCompany(name, 1)
                }}
              >
                <i className="fas fa-search" />
              </Button>
            </Col>
            <Col xs="auto">
              <Button
                color="primary"
                onClick={() => {
                  setName('')
                  getCompany('', 1)
                }}
              >
                <i className="far fa-trash-alt" />
              </Button>
            </Col>
          </Col>
        </Row>

        <Row className="mt-0">
          <div className="col">
            <Card className="shadow">
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">id</th>
                    <th scope="col">nome</th>
                    <th scope="col">email</th>
                    <th scope="col">cpf</th>
                    <th scope="col">status</th>
                    <th scope="col">Ação</th>
                  </tr>
                </thead>

                <tbody>
                  {usesCompany?.map((user: any) => (
                    <tr key={user.id}>
                      <th scope="row">{user.id}</th>
                      <td>{user.name}</td>
                      <td>{user.email}</td>
                      <td>{user.document ? cpfCnpjMask(user.document) : ''}</td>
                      <td>{user.status}</td>
                      <td>
                        {user.is_holder && (
                          <Button
                            size="sm"
                            color="secondary"
                            onClick={() => {
                              setUserSelected(user.id)
                              setModalCompany(true)
                            }}
                            style={{ width: 40 }}
                          >
                            <i className="far fa-paper-plane" />
                          </Button>
                        )}
                        {user.is_holder && (
                          <Button
                            size="sm"
                            color="primary"
                            onClick={() => {
                              setUserSelected(user.id)
                              setModalRemove(true)
                            }}
                            style={{ width: 40 }}
                          >
                            <i className="far fa-trash-alt" />
                          </Button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {paginationOn && (
                <PaginationComponent
                  numberOfPages={numberOfPages}
                  page={page}
                  setPage={setPage}
                />
              )}
            </Card>
          </div>
        </Row>
      </CardBody>
      {modalCompany && (
        <ModalCompanies
          setModal={setModalCompany}
          modal={modalCompany}
          userId={userSelected}
          setLoading={setLoading}
          action={getCompany}
        />
      )}
      {contentModalRemove()}
      {showModal && (
        <ModalContent>
          {usersinvalid.length > 0 && <ContentCpfsInvalid />}
          {usersinvalid.length === 0 && (
            <div className="d-flex flex-column h-100 justify-content-space-between">
              <div className="text-center">
                <h1 className="text-uppercase">
                  {typeUser === 'remove'
                    ? 'Remover usuários'
                    : `Importador de Usuário`}
                </h1>
                <p className="font-weight-bold text-muted">
                  {`Selecione ou arraste o arquivo ${
                    typeUser === 'remove' ? '.CSV' : '.XLSX'
                  } para ${
                    typeUser === 'remove'
                      ? 'remover os usuários!'
                      : `importar os novos
                    usuários!`
                  } `}
                </p>
                {usersinvalid.length > 0 && <ContentCpfsInvalid />}
              </div>

              {typeUser === 'dependente' && (
                <div className=" align-self-center justify-content-center">
                  <Button
                    color="primary"
                    outline
                    href="https://starbem-production.nyc3.digitaloceanspaces.com/generic/import-b2b/Importador%20de%20colaboradores%20dependentes.xlsx"
                    className="lg-3"
                  >
                    Baixar modelo
                  </Button>
                </div>
              )}
              {typeUser === 'titular' && (
                <div className=" align-self-center justify-content-center">
                  <Button
                    color="primary"
                    outline
                    href="https://docs.google.com/spreadsheets/d/18YJtZ_CJyQ2JdJWk71JSiic_aSij_vZZNnfFQhAkzMo/edit?gid=2040882291#gid=2040882291"
                    className="lg-3"
                    target="_blank"
                  >
                    Abrir modelo
                  </Button>
                </div>
              )}
              <div
                className="d-flex flex-column justify-content-center"
                style={{ flex: 1 }}
              >
                <ReactDropZoneInput getRootProps={getRootProps()}>
                  <input {...getInputProps()} />

                  {isDragActive ? (
                    <p
                      className="font-weight-bold text-muted"
                      style={{ fontSize: 18 }}
                    >
                      Solte o arquivo aqui...
                    </p>
                  ) : acceptedFiles.length > 0 ? (
                    <p
                      className="font-weight-bold text-muted"
                      style={{ fontSize: 18 }}
                    >
                      {acceptedFiles[0].name}
                    </p>
                  ) : (
                    <p
                      className="font-weight-bold text-muted"
                      style={{ fontSize: 18 }}
                    >
                      Arraste e solte o arquivo aqui, ou clique para
                      selecioná-lo
                    </p>
                  )}
                </ReactDropZoneInput>
              </div>
              <div className="d-flex align-items-center justify-content-end">
                <div>
                  <Button
                    disabled={
                      !fileToUpload ||
                      (!fileToUpload && typeUser === 'dependente')
                    }
                    onClick={() => handleSubmit()}
                  >
                    {typeUser === 'remove' ? 'Remover' : 'Importar'}
                  </Button>
                </div>
                <div className="ml-3">
                  <Button
                    color="secondary"
                    outline
                    onClick={() => {
                      setShowModal(false)
                    }}
                  >
                    Cancelar
                  </Button>
                </div>
              </div>
            </div>
          )}
        </ModalContent>
      )}
    </div>
  )
}

export default CollaboratorsCompany
